<template>
	<v-container>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="elevation-0">
	  		  <v-card-title primary-title>
	  		    Reporte por sucursal
	  		    <v-spacer></v-spacer>
	  		    <v-btn color="primary" @click="init()" rounded small>Actualiar</v-btn>
	  		  </v-card-title>
	  			<v-card-text>
            <v-container>
              <v-row justify="space-around">
              	<v-col cols="12" class="pa-0">
              		<v-card class="elevation-0">
              		  <v-card-title primary-title class="pa-0">
              		  	<v-spacer></v-spacer>
              		    Sucursales de INBI
              		  	<v-spacer></v-spacer>
              		  </v-card-title>
              		</v-card>
              	</v-col>
                <v-col cols="12" md="5">
                  <div id="app">
                    <div id="content">
                      <canvas ref="chartInbiContactado" style="max-height: 300px"></canvas>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="5">
                  <div id="app">
                    <div id="content">
                      <canvas ref="chartInbiIntento" style="max-height: 300px"></canvas>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" class="pa-0">
              		<v-card class="elevation-0">
              		  <v-card-title primary-title class="pa-0">
              		  	<v-spacer></v-spacer>
              		    Sucursales de FAST
              		  	<v-spacer></v-spacer>
              		  </v-card-title>
              		</v-card>
              	</v-col>

                <v-col cols="12" md="5">
                  <div id="app">
                    <div id="content">
                      <canvas ref="chartFastContactado" style="max-height: 300px"></canvas>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="5">
                  <div id="app">
                    <div id="content">
                      <canvas ref="chartFastIntento" style="max-height: 300px"></canvas>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>
	</v-container>
</template>
<script>
	import Chart from "../../plugins/chart";
	var moment = require("moment");
	moment.locale("es");
	import {mapGetters, mapActions } from 'vuex'

  export default {
    data () {
      return {
        
      }
    },

    created(){
    	this.init()
    },

    methods:{
    	init(){
	    	this.$http.get("asistencias.historial.sucursal").then(response => {
	        var chart = this.$refs.chartInbiContactado;
	        var ctx = chart.getContext("2d");
	        if(this.myChartD != null) {
	          this.myChartD.destroy();
	        }

	        const labels = []
	        for(const i in response.body){
	        	if(response.body[i].unidad_negocio == 0){
	        		labels.push(response.body[i].plantel)
	        	}
	        }

	        const dataGraf = []
	        for(const i in response.body){
	        	if(response.body[i].unidad_negocio == 0){
	        		dataGraf.push(response.body[i].contacto_inbi)
	        	}
	        }

	        this.myChartD = new Chart(ctx, {
	          type: "doughnut",
	          data: {
	            labels: labels,
	            datasets: [
	              {
	                data: dataGraf,
	                backgroundColor: ["orange", "blue"],
	                labels: labels,
	              },
	            ],
	          },
	        });
	      }).catch((error) => {
	        console.log(error);
	      });


	      this.$http.get("asistencias.historial.sucursal").then(response => {
	        var chart2 = this.$refs.chartInbiIntento;
	        var ctx = chart2.getContext("2d");
	        if(this.myChartD2 != null) {
	          this.myChartD2.destroy();
	        }

	        const labels = []
	        for(const i in response.body){
	        	if(response.body[i].unidad_negocio == 0){
	        		labels.push(response.body[i].plantel)
	        	}
	        }

	        const dataGraf = []
	        for(const i in response.body){
	        	if(response.body[i].unidad_negocio == 0){
	        		dataGraf.push(response.body[i].intento_inbi)
	        	}
	        }

	        this.myChartD2 = new Chart(ctx, {
	          type: "doughnut",
	          data: {
	            labels: labels,
	            datasets: [
	              {
	                data: dataGraf,
	                backgroundColor: ["orange", "blue"],
	                labels: labels,
	              },
	            ],
	          },
	        });
	      }).catch((error) => {
	        console.log(error);
	      });

	      this.$http.get("asistencias.historial.sucursal").then(response => {
	        var chart4 = this.$refs.chartFastContactado;
	        var ctx = chart4.getContext("2d");
	        if(this.myChartD4 != null) {
	          this.myChartD4.destroy();
	        }

	        const labels = []
	        for(const i in response.body){
	        	if(response.body[i].unidad_negocio == 1){
	        		labels.push(response.body[i].plantel)
	        	}
	        }

	        const dataGraf = []
	        for(const i in response.body){
	        	if(response.body[i].unidad_negocio == 1){
	        		dataGraf.push(response.body[i].contacto_fast)
	        	}
	        }

	        this.myChartD4 = new Chart(ctx, {
	          type: "doughnut",
	          data: {
	            labels: labels,
	            datasets: [
	              {
	                data: dataGraf,
	                backgroundColor: ["orange", "blue"],
	                labels: labels,
	              },
	            ],
	          },
	        });
	      }).catch((error) => {
	        console.log(error);
	      });



	      this.$http.get("asistencias.historial.sucursal").then(response => {
	        var chart3 = this.$refs.chartFastIntento;
	        var ctx = chart3.getContext("2d");
	        if(this.myChartD3 != null) {
	          this.myChartD3.destroy();
	        }

	        const labels = []
	        for(const i in response.body){
	        	if(response.body[i].unidad_negocio == 1){
	        		labels.push(response.body[i].plantel)
	        	}
	        }

	        const dataGraf = []
	        for(const i in response.body){
	        	if(response.body[i].unidad_negocio == 1){
	        		dataGraf.push(response.body[i].intento_fast)
	        	}
	        }

	        console.log(dataGraf)

	        this.myChartD3 = new Chart(ctx, {
	          type: "doughnut",
	          data: {
	            labels: labels,
	            datasets: [
	              {
	                data: dataGraf,
	                backgroundColor: ["orange", "blue"],
	                labels: labels,
	              },
	            ],
	          },
	        });
	      }).catch((error) => {
	        console.log(error);
	      });
    	}
    }

  }
</script>
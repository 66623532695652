<template>
  <v-container>
    <v-row>
    	<v-col cols="12">
    		<v-card>
			    <v-data-table
			      :headers="headers"
			      :items="teachers"
			      :search="search"
			    >
			    	<template v-slot:top>
				      <v-toolbar
				        flat
				      >
				        <v-toolbar-title>Clase iniciada</v-toolbar-title>
				        <v-divider
				          class="mx-4"
				          inset
				          vertical
				        ></v-divider>
				        <v-spacer></v-spacer>
				        <v-btn 
			            class="mr-2" 
			            small dark color="warning"
			            @click="exportar()">
			            <v-icon>save_alt</v-icon>
			          </v-btn>
				        <v-btn color="primary" small rounded @click="getRiesgo()">Actualizar</v-btn>
				      </v-toolbar>
	            <v-card-text>
	              <v-row>
	                <v-col cols="12" md="4" lg="3" class="pb-0">
	                  <v-autocomplete
	                    item-value="id"
	                    item-text="nombre"
	                    v-model="ciclo"
	                    return-object
	                    :items="ciclos"
	                    dense
	                    label="Ciclos"
	                    outlined
	                    hide-details
	                  ></v-autocomplete>
	                </v-col>
	                <v-col cols="12" md="3" lg="2" class="pb-0">
	                  <v-autocomplete
                      v-model="hora_inicio"
                      :items="horas"
                      dense
                      label="Hora"
                      outlined
                      hide-details
                    ></v-autocomplete>
	                </v-col>
	                <v-col cols="12">
	                	<v-text-field
						          label="Buscar"
						          v-model="search"
						          dense
						          hide-details
						          outlined
						        ></v-text-field>
	                </v-col>
	              </v-row>
	            </v-card-text>
				    </template>
			    </v-data-table>
    		</v-card>
    	</v-col>
    </v-row>

    <v-overlay :value="overlay">
      <v-card
      	width="200"
        color="primary"
        dark
      >
        <v-card-text align="center" class="text-h6">
          Espera porfavor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-text class="pt-2">
          Error al recuperar los datos
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="primary"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
	import XLSX from 'xlsx'
  export default {
    data () {
      return {
        search: '',
        headers: [
          { text: 'Id grupo', value: 'id' },
          { text: 'Grupo',    value: 'grupo' },
          { text: 'Curso',    value: 'curso' },
          { text: 'Inició',   value: 'hora_inicio' },
          { text: 'Teacher',  value: 'teacher' },
          { text: 'Fecha',    value: 'fecha'},
          { text: 'Riesgo',   value: 'riesgo' },
        ],
        teachers: [],

        overlay:false,
	      dialogError: false,
	      error:'',

	      ciclos:[],
	      ciclo:{},
	      idciclo:null,
	      hora_inicio:null,
	      horas:[]
      }
    },

    watch:{
	    ciclo () {
	      this.idciclo = this.ciclo.id
	      for(const i in this.ciclos){
	        if(this.ciclo.id == this.ciclos[i].id){
	          this.iderp = this.ciclos[i-1].iderp
            this.getHoras ()
	          if(this.hora_inicio){ this.getRiesgo() }
	        }
	      }
	    },

	    hora_inicio () {
	      if(this.hora_inicio && this.idciclo){ this.getRiesgo() }
	    }
	  },

    created () {
    	this.getCiclos()
    },

    methods: {
    	getCiclos () {
        this.$http.get('riesgo.ciclos.inbi').then(response=>{
          this.ciclos = response.body
        }).catch(error=>{
          console.log(error)
          this.loading = false
        })
      },

    	getRiesgo () {
    	 	this.overlay = true
    		this.teachers = []
    		
    		let payload = {
    			actual: this.idciclo,
    			hora_inicio: this.hora_inicio
    		}
    		this.$http.post('reportes.claseiniciada.inbi', payload).then(response=>{
        	this.teachers = response.body
        	this.overlay = false
        	console.log(response.body)
        }).catch(error=>{
        	this.error = error.body.message
        	this.dialogError = true
        	this.overlay = false
        })
    	},

    	getHoras () {
        this.$http.get('reportes.horainicio.inbi/' + this.idciclo).then(response=>{
          for(const i in response.body){
            this.horas.push(response.body[i].hora_inicio)
          }

          console.log(this.horas)
        }).catch(error=>{
          console.log(error)
          this.loading = false
        })
      },

    	s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.teachers)
        const workbook = XLSX.utils.book_new()
        const filename = 'clase_iniciada_inbi'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = this.$http.options.root + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          console.log(error);
        });

      },

    }
  }
</script>
<template>
	<v-container>
	  <v-row>
	  	<v-col cols="12">
			  <v-data-table
			    :headers="headers"
			    :items="alumnos"
			    sort-by="calories"
			    class="elevation-1"
			    :search="search"
			    dense
			    :loading="loading"
			    dense
			  >

          <template v-slot:item.estatus="{ item }">
            <v-chip
              color="red"
              dark
              v-if="item.estatus=='ALTO'"
              small
              @click="abrirEstatus(item,1)"
            >
              ALTO
            </v-chip>

            <v-chip
              color="blue"
              v-if="item.estatus=='MEDIO'"
              small
              dark
              @click="abrirEstatus(item,1)"
            >
              MEDIO
            </v-chip>

            <v-chip
              color="green"
              dark
              v-if="item.estatus=='NULO'"
              small
              @click="abrirEstatus(item,1)"
            >
              NULO
            </v-chip>
          </template>

          <template v-slot:item.estatus_ejercicios="{ item }">
            <v-chip
              color="red"
              dark
              v-if="item.estatus_ejercicios=='ALTO'"
              small
              @click="abrirEstatus(item,2)"
            >
              ALTO
            </v-chip>

            <v-chip
              color="green"
              dark
              v-if="item.estatus_ejercicios=='NULO'"
              small
              @click="abrirEstatus(item,2)"
            >
              NULO
            </v-chip>
          </template>

          <template v-slot:item.estatus_examenes="{ item }">
            <v-chip
              color="green"
              dark
              v-if="item.estatus_examenes=='NULO'"
              small
              @click="abrirEstatus(item,3)"
            >
              NULO
            </v-chip>

            <v-chip
              color="red"
              dark
              v-else
              small
              @click="abrirEstatus(item,3)"
            >
              ALTO
            </v-chip>

          </template>



          <template v-slot:item.estatus_calificaciones="{ item }">
            <v-chip
              color="red"
              dark
              v-if="item.estatus_calificaciones=='ALTO'"
              small
              @click="abrirEstatus(item,4)"
            >
              ALTO
            </v-chip>

            <v-chip
              color="green"
              dark
              v-if="item.estatus_calificaciones=='NULO'"
              small
              @click="abrirEstatus(item,4)"
            >
              NULO
            </v-chip>
          </template>

          <template v-slot:item.estatus_registro="{ item }">
            <v-chip
              color="red"
              dark
              v-if="item.estatus_registro=='ALTO'"
              small
              @click="abrirEstatus(item,5)"
            >
              ALTO
            </v-chip>

            <v-chip
              color="green"
              dark
              v-if="item.estatus_registro=='NULO'"
              small
              @click="abrirEstatus(item,5)"
            >
              NULO
            </v-chip>
          </template>

			    <template v-slot:top>
			      <v-toolbar
			        flat
			      >
			        <v-toolbar-title>Riesgo Alumno Fast</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-text-field
			          label="Buscar"
			          v-model="search"
			          dense
			          hide-details
			          outlined
			        ></v-text-field>
			        <v-spacer></v-spacer>
			        <v-btn 
		            class="mr-2" 
		            small dark color="warning"
		            @click="exportar()">
		            <v-icon>save_alt</v-icon>
		          </v-btn>
			        <v-btn color="primary" small rounded @click="getDatosRiesgo()">Actualizar</v-btn>
			      </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4" lg="3" class="pb-0">
                  <v-autocomplete
                    item-value="id"
                    item-text="nombre"
                    v-model="ciclo"
                    return-object
                    :items="ciclos"
                    dense
                    outlined
                    label="Ciclos"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
			    </template>
			  </v-data-table>
  		</v-col>
	  </v-row>

    <!-- Dialogo par aindicar que se están cargando los datos -->
    <div class="text-center">
      <v-dialog
        v-model="dialogCarga"
        hide-overlay
        persistent
        width="500"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="pt-2">
            Este proceso es largo, por favor espera...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-col cols="auto">
      <v-dialog
        v-model="dialogEstatus"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card>
          <v-toolbar
            color="primary"
            dark
            dense
            class="elevation-0"
          >información detallada</v-toolbar>
          <v-card-text>
            <div class="mt-2"><h3>{{alumnoEstatus.nombre}}</h3></div>
            <div class="mt-2" v-if="tipoEstatus == 'ALTO'"> <h4><b>Riesgo:</b><span class="red--text">   ALTO  </span></h4> </div>
            <div class="mt-2" v-if="tipoEstatus == 'MEDIO'"><h4><b>Riesgo:</b><span class="blue--text">  MEDIO </span></h4> </div>
            <div class="mt-2" v-if="tipoEstatus == 'NULO'"> <h4><b>Riesgo:</b><span class="green--text"> NULO  </span></h4> </div>
            <div class="mt-2"><b> Situación:</b> {{mensaje}} </div>
            <div class="mt-2"><b> Solución:</b></div>
            <span v-html="solucion"></span>
            <v-divider></v-divider>
            <div class="mt-2" v-if="activeFaltas"><b><h4>Faltas:</h4></b></div>
            <div v-for="(faltas , i) in alumnoEstatus.faltas" :key="i" v-if="activeFaltas">
              <div>{{faltas}}</div>
            </div>

            <div class="mt-2" v-if="activeEjercicios"><b><h4>Ejercicios:</h4></b></div>
            <div v-for="(ejercicio , i) in ejercicios" :key="i" v-if="activeEjercicios">
              <div v-if="ejercicio.calificacion == null">{{ejercicio.nombre}}</div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialogEstatus = false"
            >Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

	</v-container>
</template>

<script>
	import XLSX from 'xlsx'
	
	import {mapGetters, mapActions} from 'vuex'
  var moment = require('moment');
  moment.locale(); 

  export default {
    data: () => ({
      activeEjercicios: false,
      ejercicios:[],

      dialogCarga:false, 
      dialogEstatus:false,
      alumnoEstatus:{},
      tipoEstatus: 0,
      mensaje:'',
      solucion:'',
      activeFaltas: false,

    	loading:false,
    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Alumno',
          align: 'start',
          sortable: false,
          value: 'nombre',
        },
        { text: 'Grupo',          value: 'grupo' },
        { text: 'Curso',          value: 'curso' },
        { text: 'Nivel',          value: 'nivel' },
        { text: 'Asistencias',    value: 'estatus' },
        { text: 'Ejercicios',     value: 'estatus_ejercicios' },
        { text: 'Examenes',       value: 'estatus_examenes' },
        { text: 'Puntos',         value: 'estatus_calificaciones'},
        { text: 'Registro',       value: 'estatus_registro'},
        { text: 'Pago anterioir', value: 'monto'},
      ],
      alumnos: [],
      calificaciones:[],
      faltantes:[],
      ciclos:[],
      ciclo:{},
      idciclo:0,
      grupos:[],
      iderp:0,

    }),

    computed: {
    	...mapGetters('login',['getusuarioRiesgo'])
    },

    watch:{
      ciclo () {
        this.idciclo = this.ciclo.id
        for(const i in this.ciclos){
          if(this.ciclo.id == this.ciclos[i].id){
            this.iderp = this.ciclos[i-1].iderp
            this.getDatosRiesgo()
          }
        }
        // ahora que ya tenemos el ciclo, mandamos a llamar a la funcion de traer los datos del ciclo
      }
    },


    created () {
      // Pasp 1: Obtener los ciclos
      this.getCiclos()
    },

    methods: {
      getCiclos(){
        this.$http.get('riesgo.ciclos.fast').then(response=>{
          this.ciclos = response.body
        }).catch(error=>{
          console.log(error)
          this.loading = false
        })
      },

      getCalificaciones () {
        // creamos nuestro objeto con el idciclo
        var payload = { ciclo: this.idciclo }
        // Mandamos a traer todas las calificaciones de los alumnos y mandamos como paramtero el idciclo
        this.$http.post('riesgo.calificaciones',payload).then(response=>{
          // Mandamos a calcular las calificaciones y mandamos como parametro las calificaciones de los alumno
          // NOTA: las calificaciones son todas, falta calcularlas y agruparalas para sacar el promedio de las calificaciones
          this.calcularCalificaciones(response.body)
        }).catch(error=>{
          console.log(error)
          this.loading = false
          this.dialogCarga = false
        })
      },

      calcularCalificaciones(value){
        // Creamos un arreglo de las calificaciones
        var arrayCalificaciones = value

        // Sacamos los datos repetidos
        let alumnos = value.map(item=>{
          return [item.nombre,item]
        });

        // Creamos un map de los alumnos
        var alumnosArray = new Map(alumnos); // Pares de clave y valor
        // y ahora si, extraemos los valores unicos
        let unicos = [...alumnosArray.values()]; // Conversión a un array
        console.log(unicos)

        // Hacemos un recorrido de todos los valores unicos
        for(const i in unicos){
          // Iniciamos todas las variables que vamos a utilizar para las sumas y conteos
          var extraordinario        = 0

          var sumaCalifEjercicio    = 0
          var cantEjercicio         = 0
          
          var sumaCalifExamen       = 0
          var cantExamen            = 0
          
          var sumaTotal             = 0
          
          var sumaAsistencia        = 0
          var cantAsistencia        = 0
          var sumaFalta             = 0
          var sumaRetardo           = 0
          var sumaJustificada       = 0
          
          var sumaCalifExamenExtra  = 0
          
          // Hacemos un recorrido para saber que datos le pertenecen a que alumno de los valores unicos
          //  Como es un recorrido, esto significa que estamos busando las calificaciones del alumno en la posición i
          for(const j in arrayCalificaciones){
            // Si el alumno es igual al que estamos buscando
            if(unicos[i].id_alumno == arrayCalificaciones[j].id_alumno){
              // Varificamos sus ejercicios
              if(arrayCalificaciones[j].tipo_evaluacion == 1 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
                // Hacemos el conteo y la suma de las calificaciones
                sumaCalifEjercicio = sumaCalifEjercicio + arrayCalificaciones[j].calificacion
                cantEjercicio = cantEjercicio + 1
              }else{
                // Si no es ejercicios es examen (normal o recuperación)
                // Exmane normal o recuperación
                if(arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==2 || arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
                  // Hamos la suma y el conteo
                  sumaCalifExamen = sumaCalifExamen + arrayCalificaciones[j].calificacion
                  cantExamen = cantExamen + 1
                }

                // También verificamos si es extraordianrio, por que también eso es posible, tener un examen extraordinario
                if(arrayCalificaciones[j].tipo_evaluacion == 3 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
                  sumaCalifExamenExtra = sumaCalifExamenExtra + arrayCalificaciones[j].calificacion
                  extraordinario = extraordinario + 1
                }
              }

            }
          }

          // Haciendo el calculo para los puntos obtenidos de asistencias
          sumaAsistencia  = unicos[i].asistencia
          sumaFalta  = unicos[i].falta
          sumaRetardo  = unicos[i].retardo
          sumaJustificada  = unicos[i].justificada
          // Sacamos los puntos de las asistencias
          var ptsAsistencia = (unicos[i].valor_asistencia / unicos[i].total_dias_laborales)
          // Hacemos la suma de los puntos
          var sumaTotalAsistencia = (sumaAsistencia * ptsAsistencia) +  (sumaRetardo * (ptsAsistencia/2))

          // Calculamos los ejercicios y los puntos por ejercicios y los puntos finales
          var divEjercico =  (sumaCalifEjercicio/unicos[i].num_ejercicios)
          var ptsEjercicio = unicos[i].valor_ejercicios/100
          var ptsFinalesEjercicios = divEjercico * ptsEjercicio

          // Calculamos los examenes y los puntos por examenes y los puntos finales
          var divExamenes =  (sumaCalifExamen/unicos[i].num_examenes)
          var ptsExamenes = unicos[i].valor_examenes/100
          var ptsFinalesExamenes = divExamenes * ptsExamenes

          // Vemos la cantidad de asistencias
          cantAsistencia = sumaAsistencia +  sumaRetardo 

          // hacemos el objeto que vamos a insertar a buscar en nuestro arreglo inicial de los alumnos
          //  para poder ahora si a cada alumno ponerle su calificacione
          var payload = {
            id_alumno:              unicos[i].id_alumno,
            diferencia:             unicos[i].diferencia,
            total:                  (ptsFinalesEjercicios + ptsFinalesExamenes + sumaTotalAsistencia).toFixed(2),
          }
          if(payload.total < 35){console.log(payload)}
          this.calificaciones.push(payload)
        }

        // Ahora comparamos cada una de las calificaciones con la lista de alumnos que tenemos de riesgo, para ingresarle su calificación y ver si esta en riesgo o no por puntors

        for(const m in this.alumnos){
          this.alumnos[m].estatus_calificaciones = 'NULO'
        }

        for(const m in this.alumnos){
          for(const n in this.calificaciones){
            if(this.alumnos[m].id_alumno == this.calificaciones[n].id_alumno){
              this.alumnos[m].calificacion == this.calificaciones[n].total
              if(this.calificaciones[n].diferencia >= 15){
                if(this.calificaciones[n].total < 35){
                  console.log('a')
                  this.alumnos[m].estatus_calificaciones = 'ALTO'
                }
              }
            }

            if(this.alumnos[m].estatus_registro == 'ALTO'){
              this.alumnos[m].estatus_calificaciones = 'ALTO'
              this.alumnos[m].estatus_examenes = 'ALTO'
            }
          }
        }

        for(const i in this.alumnos){
          for(const j in this.alumnos[i].faltas){
            this.alumnos[i].faltas[j] = moment(this.alumnos[i].faltas[j].substr(0,10)).format("LLLL")
          }
        }

        // Quitamos los loader
        this.dialogCarga = false
      },

      // Obtener la información de los laumnos en riesgo de ese ciclo
      getDatosRiesgo () {
        // Primero traemos los que faltan por entrar a la plataforma
        this.$http.post('riesgo.alumnos.registro').then(response=>{
          // Los guardamos
          this.faltantes  = response.body
          // Activamos el loading de la tabla, activamos el loader especial
          this.dialogCarga = true
          // Vaciamos nuestro arreglo de datos 
        	this.alumnos = []
          // Cramos nuestro objeto con el ciclo que vamos a traer
        	var payload = { ciclo: this.idciclo, iderp: this.iderp }
          // Mandamos a consultar la api de alumnos de riesgo en fast y mandamos como parametro el idciclo
          this.$http.post('riesgo.alumnos.pago',payload).then(response=> {
            var pagos = response.body
            // Mandamos a consultar la api de alumnos de riesgo en fast y mandamos como parametro el idciclo
        		this.$http.post('riesgo.alumnos.fast',payload).then(response=>{
              // Guardamos los alumnos obtenidos en un arreglo de objetos (alumnos)
            	for(const j in response.body){
                if(this.getusuarioRiesgo.idplanteles.includes( response.body[j].id_plantel )){
                  this.alumnos.push(response.body[j])
                }
              }
            	
              // Recorremos a los usuarios
              for(const i in this.alumnos){
                // Aquí buscamos si el alumno pertenece a los alumnos que no se han registrado a la plataforma
                const alumno = this.faltantes.find(faltante => faltante.id == this.alumnos[i].id_alumno)
                // Y si si pertenece, le ponemos en estatus_registo "ALTO"
                if(alumno){this.alumnos[i].estatus_registro = 'ALTO'}

                // Ahora buscamos cuanto dinero pago el alumno o si es nuevo ingreso 
                const pago   = pagos.find(pago => pago.id_alumno == this.alumnos[i].id_alumno)
                if(pago){this.alumnos[i].monto = pago.total_pagado}
              }

            	// Ahora, hay que consultar las calificaciones de esos alumnos que están en riesgo
              this.getCalificaciones()
            }).catch(error=>{
            	console.log(error)
            	this.loading = false
            })
          }).catch(error=>{
            console.log(error)
            this.loading = false
          })
        }).catch(error=>{
          console.log(error)
          this.loading = false
        })
      },

      // EXCEL
      exportar(){
        let xls = {
          data:      this.alumnos,
          tHeaders : ['Codigo','Nombre', 'Sal', 'Labortorio'],
          filterVal: ['codigo','nomart', 'sal', 'lab'],
          nomfile :  "Productos"
        }
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data = XLSX.utils.json_to_sheet(this.alumnos)
        const workbook = XLSX.utils.book_new()
        const filename = 'Riesgo_General_fast'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        console.log(file)
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = this.$http.options.root + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },

      alertaEstatus(value, tipo){
        this.dialogEstatus = true
      },

      abrirEstatus(value, tipo){
        // ver ejercicios no contestados
        var payload = {
          ciclo:      this.idciclo,
          id_alumno:  value.id_alumno,
          nivel:      value.nivel,
          curso:      value.curso
        }

        this.$http.post('riesgo.ejercicios.fast',payload).then(response=> {
          this.ejercicios = response.body
          console.log(this.ejercicios)
        


          this.alumnoEstatus = {}
          this.dialogEstatus = true
          this.alumnoEstatus = value

          // Estatus por inasistencias
          if(tipo == 1){
            this.activeFaltas = true

            this.activeEjercicios = false          
            if(this.alumnoEstatus.estatus == 'ALTO'){
              this.tipoEstatus = 'ALTO'
              this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es ALTO debido a que tiene un alto porcentaje de falta en su ciclo'
              this.solucion = `<div> Hablarle al alumno para brindarle la siguiente información:  <br/> <br/>
  ¡Hola, buen día <b>${this.alumnoEstatus.nombre}!</b> espero que estés muy bien <br/>

  Mi nombre es <b>${this.getusuarioRiesgo.nombre_completo}</b> y me comunico de su escuela de inglés <b>FAST ENGLISH</b>, hemos estado validando su situación académica y vemos que tiene algunas inasistencias, y eso le ha generado una calificación baja en cuanto a la pérdida de puntos de asistencia de su tu nivel, le recomendamos ponerse al corriente con sus clases, ya que todavía estamos a tiempo para alcanzar el pase.<br/>
  Nos gustaría que obtuviera la máxima calificación en su nivel, ¿hay algo en que le pueda apoyar? ¿tiene algún problema o inconveniente en el horario o en el grupo? <br/></br>

  Quedamos a la espera de su respuesta y cualquier duda o comentario estoy para ayudarle  <br/> <br/> </div>` 
            }

            if(this.alumnoEstatus.estatus == 'MEDIO'){
              this.tipoEstatus = 'MEDIO'
              this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es MEDIO debido a que tiene un porcentaje de inasistencias aceptables, pero tiende a convertirse a un riesgo ALTO'
              this.solucion = 'Hablar con el alumno para indicarle que, a causa de sus inasistencias se encuentra en un estatus medio pero con probabilidades de pasar a un riesgo alto'
            }

            if(this.alumnoEstatus.estatus == 'NULO'){
              this.tipoEstatus = 'NULO'
              this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es NULO debido a que no a faltado ni una sola vez'
              this.solucion = 'El alumno va perfecto en sus asistencias en la clase'
            }
          }

          // Estatus por ejercicios
          if(tipo == 2){
            this.activeFaltas = false
            this.activeEjercicios = true
            if(this.alumnoEstatus.estatus_ejercicios == 'ALTO'){
              this.tipoEstatus = 'ALTO'
              this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es ALTO debido a que no va al corriente con sus ejercicios'
              this.solucion = `<div>Hablarle al alumno para brindarle la siguiente información:<br/> <br/>
  ¡Hola, buen día <b>${this.alumnoEstatus.nombre}!<br/> espero que estés muy bien

  Mi nombre es <b>${this.getusuarioRiesgo.nombre_completo}<br/> y me comunico de su escuela de inglés <b>FAST ENGLISH<br/>, hemos estado validando su situación académica y vemos que tiene algunos ejercicios pendientes por realizar, y eso le ha generado una calificación baja en cuanto a la pérdida de puntos de ejercicios en su nivel, le recomendamos ponerse al corriente con sus ejercicios, ya que todavía estamos a tiempo para alcanzar el pase.<br/> 
  Nos gustaría que obtuviera la máxima calificación en su nivel, ¿hay algo en que le pueda apoyar en cuanto a la plataforma, actividades? ¿tiene algún problema o inconveniente? <br/> 

  Quedamos a la espera de su respuesta y cualquier duda o comentario estoy para ayudarle <br/> <br/></div>`
            }

            if(this.alumnoEstatus.estatus_ejercicios == 'NULO'){
              this.tipoEstatus = 'NULO'
              this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es NULO debido a que va al corriente con sus actividades'
              this.solucion = 'El alumno va perfecto en sus actividades en la clase'
            }
          }

          // Estatus por examenes
          if(tipo == 3){
            this.activeFaltas = false
            this.activeEjercicios = false
            if(this.alumnoEstatus.estatus_examenes == 'ALTO'){
              this.tipoEstatus = 'ALTO'
              this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es ALTO debido a no ha realizado su examen de medio curso'
              this.solucion = `<div> Hablarle al alumno para brindarle la siguiente información:<br/> <br/>
  ¡Hola, buen día  <b>${this.alumnoEstatus.nombre}!<br/> espero que estés muy bien<br/> 

  Mi nombre es  <b>${this.getusuarioRiesgo.nombre_completo}<br/> y me comunico de su escuela de inglés <b>FAST ENGLISH<br/>, hemos estado validando su situación académica y validamos que tienes pendiente por realizar tu examen de medio término, el cual actualmente esta activo un examen de recuperación de midterm, el cual le podrá ayudar a obtener puntaje para <br/> 
  Nos gustaría que obtuviera la máxima calificación en su nivel, ¿hay algo en que le pueda apoyar en cuanto a la plataforma o su examen? ¿tiene algún problema o inconveniente? <br/> 

  Quedamos a la espera de su respuesta y cualquier duda o comentario estoy para ayudarle <br/> <br/></div>`
            }

            if(this.alumnoEstatus.estatus_examenes == 'NULO'){
              this.tipoEstatus = 'NULO'
              this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es NULO debido a que ya ha realizado su examen de medio termino'
              this.solucion = 'El alumno ya realizo su examen de medio termino'
            }
          }


          // Estatus por puntos
          if(tipo == 4){
            this.activeFaltas = false
            this.activeEjercicios = false
            if(this.alumnoEstatus.estatus_calificaciones == 'ALTO'){
              this.tipoEstatus = 'ALTO'
              this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es ALTO debido a que tiene porcentaje de puntos muy bajo y tiende a repobar el curso'
              this.solucion = 'Hablar con el alumno para indicarle que realice todos sus ejercciios y demás si se le puede apoyar en algo para que se ponga al corriente con actividades, ejercicios y más'
            }

            if(this.alumnoEstatus.estatus_calificaciones == 'NULO'){
              this.tipoEstatus = 'NULO'
              this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es NULO debido a que su puntaje en el curso va bien'
              this.solucion = 'El alumno va bien con sus puntos en el curso'
            }
          }
          
          // Estatus por registo
          if(tipo == 5){
            this.activeFaltas = false
            this.activeEjercicios = false
            if(this.alumnoEstatus.estatus_registro == 'ALTO'){
              this.tipoEstatus = 'ALTO'
              this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es ALTO debido a que no se ha registrado en la plataforma'
              this.solucion = 'Hablar con el alumno para indicarle que tiene que ingresar a la plataforma, ya que ahí es donde realizará sus ejercicios'
            }

            if(this.alumnoEstatus.estatus_registro == 'NULO'){
              this.tipoEstatus = 'NULO'
              this.mensaje = 'El estatus del alumno(a) ' + this.alumnoEstatus.nombre + ' es NULO debido a que ya se ha registrado en la plataforma'
              this.solucion = 'El alumno ya esta registrado'
            }
          }
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });
      }
    },
  }
</script>

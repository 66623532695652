<template>
	<v-container>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card>
	  		  <v-card-title primary-title>
	  		    Reporte general
	  		    <v-spacer></v-spacer>
	  		    <v-btn color="primary" @click="init()">Actualiar</v-btn>
	  		  </v-card-title>
	  			<v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div id="app">
                    <div id="content">
                      <canvas ref="chartDesarrollo" style="max-height: 350px"></canvas>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>
	</v-container>
</template>
<script>
	import Chart from "../../plugins/chart";
	var moment = require("moment");
	moment.locale("es");
	import {mapGetters, mapActions } from 'vuex'

  export default {
    data () {
      return {
        
      }
    },

    created(){
    	this.init()
    },

    methods:{
    	init(){
	    	this.$http.get("asistencias.historial.escuela").then((response) => {
	        console.log(response.body);
	        var chart = this.$refs.chartDesarrollo;
	        var ctx = chart.getContext("2d");
	        if(this.myChartD != null) {
	          this.myChartD.destroy();
	        }

	        var sumaContacto = response.body.contacto_fast + response.body.contacto_inbi
	        var sumaIntento  = response.body.intento_fast + response.body.intento_inbi

	        this.myChartD = new Chart(ctx, {
	          type: "doughnut",
	          data: {
	            labels: [
	              "Alumno contactado: "     + sumaContacto,
	              "Intentos de llamada: "   + sumaIntento
	            ],
	            datasets: [
	              {
	                data: [
	                  sumaContacto,
	                  sumaIntento,
	                ],
	                backgroundColor: ["#3ACCD8", "#0B2550"],
	                labels: [ "Alumno contactado", "Intentos de llamada",
	                ],
	              },
	            ],
	          },
	        });
	      }).catch((error) => {
	        console.log(error);
	      });
    	}
    }

  }
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/usuario/Login.vue'
import Home from '@/views/home/Home.vue'
import About from '@/views/About.vue'
import Asistencias from '@/views/usuario/Asistencias.vue'
import CheckAsistencia from '@/views/usuario/CheckAsistencia.vue'
import AsisGeneral from '@/views/usuario/AsisGeneral.vue'
import Calificaciones           from '@/views/usuario/Calificaciones.vue'
import store                    from '@/store'

import ReporteGeneral           from '@/views/reportes/ReporteGeneral.vue'
import ReporteEscuela           from '@/views/reportes/ReporteEscuela.vue'
import ReporteSucursal          from '@/views/reportes/ReporteSucursal.vue'
import Historial                from '@/views/reportes/Historial.vue'

import riesgoAsistenciasFast    from '@/views/riesgo/riesgoAsistenciasFast.vue'
import riesgoAsistenciasInbi    from '@/views/riesgo/riesgoAsistenciasInbi.vue'

import riesgoGeneralFast        from '@/views/riesgo/riesgoGeneralFast.vue'
import riesgoGeneralInbi        from '@/views/riesgo/riesgoGeneralInbi.vue'
import riesgoRegistroFast       from '@/views/riesgo/riesgoRegistroFast.vue'
import riesgoRegistroInbi       from '@/views/riesgo/riesgoRegistroInbi.vue'
import riesgoPtsFast            from '@/views/riesgo/riesgoPtsFast.vue'
import riesgoPtsInbi            from '@/views/riesgo/riesgoPtsInbi.vue'

// Riesgo Teachers
import riesgoTeacherFast        from '@/views/teacher/riesgoTeacherFast.vue'
import riesgoTeacherInbi        from '@/views/teacher/riesgoTeacherInbi.vue'
import riesgoxTeacherFAST       from '../views/teacher/riesgoxTeacherFAST.vue'
import riesgoxTeacherINBI       from '../views/teacher/riesgoxTeacherINBI.vue'
import asistenciaTeacherFast    from '../views/teacher/asistenciaTeacherFast.vue'
import asistenciaTeacherInbi    from '../views/teacher/asistenciaTeacherInbi.vue'
import claseIniciadaFast        from '../views/teacher/claseIniciadaFast.vue'
import claseIniciadaInbi        from '../views/teacher/claseIniciadaInbi.vue'

// RECEPCION
import riesgoRescepcionistaFast from '@/views/rescepcionista/riesgoRescepcionistaFast.vue'
import riesgoRescepcionistaInbi from '@/views/rescepcionista/riesgoRescepcionistaInbi.vue'

import reporteRiesgoEscuelaFast from '@/views/reportes/reporteRiesgoEscuelaFast.vue'
import reporteRiesgoEscuelaInbi from '@/views/reportes/reporteRiesgoEscuelaInbi.vue'

//Imagenes LMS
import AprobarImagenesFast      from '../views/imagenes/AprobarImagenFast.vue'
import AprobarImagenesInbi      from '../views/imagenes/AprobarImagenInbi.vue'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: '',
    base: process.env.BASE_URL,
    routes: [

      { path: '/about'                   , name: 'about'                    , component: About                  , meta: { ADMIN: true, RECEPCION: true, libre: true } },
      { path: '/login/:id'               , name: 'login'                    , component: Login                  , meta: { ADMIN: true, RECEPCION: true, libre: true } },
      { path: '/home'                    , name: 'Home'                     , component: Home                   , meta: { ADMIN: true, RECEPCION: true } },
      { path: '/calificaciones'          , name: 'Calificaciones'           , component: Calificaciones         , meta: { ADMIN: true, RECEPCION: true } },
      { path: '/asistencias'             , name: 'Asistencias'              , component: Asistencias            , meta: { ADMIN: true, RECEPCION: true } },

      { path: '/asisgeneral'             , name: 'AsisGeneral'              , component: AsisGeneral            , meta: { ADMIN: true } },
      { path: '/checkasistencia'         , name: 'CheckAsistencia'          , component: CheckAsistencia        , meta: { ADMIN: true } },

      // Reportes
      { path: '/reportegeneral'          , name: 'ReporteGeneral'           , component: ReporteGeneral         , meta: { ADMIN: true } },
      { path: '/reporteescuela'          , name: 'ReporteEscuela'           , component: ReporteEscuela         , meta: { ADMIN: true } },
      { path: '/reportesucursal'         , name: 'ReporteSucursal'          , component: ReporteSucursal        , meta: { ADMIN: true } },
      { path: '/historial'               , name: 'Historial'                , component: Historial              , meta: { ADMIN: true } },

      // Panel de riesgo
      // Reportes
      { path: '/riesgogeneralfast'       , name: 'riesgoGeneralFast'       , component: riesgoGeneralFast       , meta: { ADMIN: true, RIESGO: true } },
      { path: '/riesgogeneralinbi'       , name: 'riesgoGeneralInbi'       , component: riesgoGeneralInbi       , meta: { ADMIN: true, RIESGO: true } },

      { path: '/riesgoregistrofast'      , name: 'riesgoRegistroFast'      , component: riesgoRegistroFast      , meta: { ADMIN: true, RIESGO: true } },
      { path: '/riesgoregistroinbi'      , name: 'riesgoRegistroInbi'      , component: riesgoRegistroInbi      , meta: { ADMIN: true, RIESGO: true } },
      { path: '/riesgoptsfast'           , name: 'riesgoPtsFast'           , component: riesgoPtsFast           , meta: { ADMIN: true, RIESGO: true } },
      { path: '/riesgoptsinbi'           , name: 'riesgoPtsInbi'           , component: riesgoPtsInbi           , meta: { ADMIN: true, RIESGO: true } },
      { path: '/riesgoasistenciasfast'   , name: 'riesgoAsistenciasFast'   , component: riesgoAsistenciasFast   , meta: { ADMIN: true, RIESGO: true } },
      { path: '/riesgoasistenciasinbi'   , name: 'riesgoAsistenciasInbi'   , component: riesgoAsistenciasInbi   , meta: { ADMIN: true, RIESGO: true } },

      { path: '/riesgoteacherfast'       , name: 'riesgoTeacherFast'       , component: riesgoTeacherFast       , meta: { TEACHER: true, RIESGO: true } },
      { path: '/riesgoteacherinbi'       , name: 'riesgoTeacherInbi'       , component: riesgoTeacherInbi       , meta: { TEACHER: true, RIESGO: true } },

      { path: '/riesgoRescepcionistaFast', name: 'riesgoRescepcionistaFast', component: riesgoRescepcionistaFast, meta: { RECEPCION: true, RIESGO: true } },
      { path: '/riesgoRescepcionistaInbi', name: 'riesgoRescepcionistaInbi', component: riesgoRescepcionistaInbi, meta: { RECEPCION: true, RIESGO: true } },

      { path: '/reporteriesgoescuelafast', name: 'reporteRiesgoEscuelaFast', component: reporteRiesgoEscuelaFast, meta: { ADMIN: true, RIESGO: true } },
      { path: '/reporteriesgoescuelainbi', name: 'reporteRiesgoEscuelaInbi', component: reporteRiesgoEscuelaInbi, meta: { ADMIN: true, RIESGO: true } },

      { path: '/aprobarimagenesfast'     , name: 'AprobarImagenesFast'     , component: AprobarImagenesFast     , meta: { ADMIN: true, RIESGO: TextTrackCueList } },
      { path: '/aprobarimagenesinbi'     , name: 'AprobarImagenesInbi'     , component: AprobarImagenesInbi     , meta: { ADMIN: true, RIESGO: TextTrackCueList } },

      { path: '/riesgoxteacherfast'      , name: 'riesgoxTeacherFAST'      , component: riesgoxTeacherFAST      , meta: { ADMIN: true, RIESGO: TextTrackCueList } },
      { path: '/riesgoxteacherinbi'      , name: 'riesgoxTeacherINBI'      , component: riesgoxTeacherINBI      , meta: { ADMIN: true, RIESGO: TextTrackCueList } },
      { path: '/asistenciateacherfast'   , name: 'asistenciaTeacherFast'   , component: asistenciaTeacherFast   , meta: { ADMIN: true, RIESGO: TextTrackCueList } },
      { path: '/asistenciateacherinbi'   , name: 'asistenciaTeacherInbi'   , component: asistenciaTeacherInbi   , meta: { ADMIN: true, RIESGO: TextTrackCueList } },
      { path: '/claseiniciadafast'       , name: 'claseIniciadaFast'       , component: claseIniciadaFast       , meta: { ADMIN: true, RIESGO: TextTrackCueList } },
      { path: '/claseiniciadainbi'       , name: 'claseIniciadaInbi'       , component: claseIniciadaInbi       , meta: { ADMIN: true, RIESGO: TextTrackCueList } },
    ]
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.login.usuarioRiesgo.cargo == 'ADMIN') {
    if (to.matched.some(record => record.meta.ADMIN)) {
      next()
    }
  } else if (store.state.login.usuarioRiesgo.cargo == 'RECEPCION') {
    if (to.matched.some(record => record.meta.RECEPCION)) {
      next()
    }
  } else if (store.state.login.usuarioRiesgo.cargo == 'TEACHER') {
    if (to.matched.some(record => record.meta.TEACHER)) {
      next()
    }
  } else {
    next({
      name: 'login'
    })
  }
})

export default router
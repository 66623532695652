<template>
  <v-app>
    <v-app-bar
      v-if="getusuarioRiesgo"
      app
      color="primary"
      class="elevation-0"
      dark
      dense
      >
      <v-btn
        icon
        text
        dark
        @click="drawer=!drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
        
      <v-spacer></v-spacer>
      <v-btn icon @click="salir()"><v-icon>mdi-exit-to-app</v-icon></v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="getusuarioRiesgo"
      :mini-variant.sync="mini"
    > 
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="@/assets/logo.png"  max-height="150" contain v-if="inbi"></v-img>
        <v-img src="@/assets/logo-app.png"  max-height="150" contain v-else></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ getusuarioRiesgo.nombre_completo }}
          </v-list-item-title>
          <v-list-item-subtitle>{{
            getusuarioRiesgo.perfil
          }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <!-- Nivel administrador -->

      <v-list dense class="pb-0" v-if="getusuarioRiesgo.cargo=='ADMIN' || getusuarioRiesgo.cargo=='RIESGO'"  nav>
        <v-list-group
          v-for="item in menudesplegableAdmin"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            class="pl-5"
            v-for="child in item.items"
            :key="child.title"
            :to="{ name: child.path }"
          >

            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <!-- Recepcion -->
      <v-list dense class="pb-0" v-if="getusuarioRiesgo.cargo=='RECEPCION' && inbi == true">
        <v-subheader>Operaciones</v-subheader>
        <v-list-item
          v-for="(menu, k) in menudesplegableInbi"
          :key="k"
          :to="{name: menu.path}"
          :class="menu.active ? 'red white--text' : ''"
        >
          <v-list-item-action>
            <v-icon color="primary">{{ menu.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content class="pa-0">
            <v-list-item-title v-text="menu.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>


      <v-list dense class="pb-0" v-if="getusuarioRiesgo.cargo=='RECEPCION' && inbi == false" nav>
        <v-list-group
          v-for="item in menudesplegableFast"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            class="pl-5"
            v-for="child in item.items"
            :key="child.title"
            :to="{ name: child.path }"
          >

            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <!-- Menu teacher -->
      <v-list dense class="pb-0" v-if="getusuarioRiesgo.cargo=='TEACHER'">
        <v-subheader>Operaciones</v-subheader>
        <v-list-item
          v-for="(menu, k) in menudesplegableteacher"
          :key="k"
          :to="{name: menu.path}"
          :class="menu.active ? 'red white--text' : ''"
        >
          <v-list-item-action>
            <v-icon color="primary">{{ menu.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content class="pa-0">
            <v-list-item-title v-text="menu.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      
    </v-navigation-drawer>

    <v-main>
      <v-container class="elevation-0 transparent pa-0" fluid>
        <v-slide-y-transition mode="out-in">
          <router-view/>
        </v-slide-y-transition>
      </v-container> 
    </v-main>
  </v-app>
</template>

<script>
  

  import {mapGetters, mapActions } from 'vuex'
  export default {

    
    name: 'App',

    data: () => ({
      drawer: true,
      mini:null,
      menu: [],
      menuTienda:[],
      active:false,
      ciaws:'',
      logo:'',

      unidad_negocio:'',

      inbi:false,
      drawer: true,
      menu: false,

      menudesplegableFast: [
        {
          action: 'Operaciones',
          items: [
            {title:'Inasistencias'              , path:'Asistencias'                        , icon:'mdi-account-check',   admin:true, usuario: true, fast: true, inbi: true},
            {title:'Calificaciones'             , path:'Calificaciones'                     , icon:'mdi-clipboard-check', admin:true, usuario: true, fast: true, inbi: true},
            {title:'Riesgo Alumno Fast'         , path:'riesgoRescepcionistaFast'           , icon:'mdi-alert-octagon',   fast:true,  inbi: false},
          ],
          title: 'CRM Prospectos',
        }
      ],

      menudesplegableInbi:[
        {title:'Inasistencias'              , path:'Asistencias'                        , icon:'mdi-account-check',   admin:true, usuario: true, fast: true, inbi: true},
        {title:'Calificaciones'             , path:'Calificaciones'                     , icon:'mdi-clipboard-check', admin:true, usuario: true, fast: true, inbi: true},
        {title:'Riesgo Alumno Inbi'         , path:'riesgoRescepcionistaInbi'           , icon:'mdi-alert-octagon',   admin:true, usuario: true},
      ],

      menudesplegableteacher:[
        {title:'Riesgo Fast'                , path:'riesgoTeacherFast'                  , icon:'mdi-alert-octagon',   admin:true, usuario: true},
        {title:'Riesgo Inbi'                , path:'riesgoTeacherInbi'                  , icon:'mdi-alert-octagon',   admin:true, usuario: true},
      ],

      menudesplegableteachers:[
      ],

      menudesplegableAdmin: [
        {
          action: 'mdi-book',
          items: [
            {title:'Inasistencias'              , path:'AsisGeneral'                        , icon:'mdi-account-check',   admin:true, usuario: true},
            {title:'Revisión Asistencias'       , path:'CheckAsistencia'                    , icon:'mdi-book-variant',    admin:true, usuario: false},
            {title:'Reportes General'           , path:'ReporteGeneral'                     , icon:'mdi-chart-pie',       admin:true, usuario: false},
            {title:'Reportes Escuela'           , path:'ReporteEscuela'                     , icon:'mdi-chart-pie',       admin:true, usuario: false},
            {title:'Reportes Sucursal'          , path:'ReporteSucursal'                    , icon:'mdi-chart-pie',       admin:true, usuario: false},
          ],
          title: 'Operaciones',
        },

        {
          action: 'mdi-book',
          items: [
            {title:'Alumnos Fast'        , path:'riesgoAsistenciasFast'              , icon:'mdi-school',            admin:true, usuario: true},
            {title:'Alumnos Inbi'        , path:'riesgoAsistenciasInbi'              , icon:'mdi-school',            admin:true, usuario: true},
            {title:'Escuela Fast'        , path:'reporteRiesgoEscuelaFast'           , icon:'mdi-home-modern',       admin:true, usuario: false},
            {title:'Escuela Inbi'        , path:'reporteRiesgoEscuelaInbi'           , icon:'mdi-home-modern',       admin:true, usuario: false},
            {title:'Puntos  Fast'        , path:'riesgoPtsFast'                      , icon:'mdi-school',            admin:true, usuario: true},
            {title:'Puntos  Inbi'        , path:'riesgoPtsInbi'                      , icon:'mdi-school',            admin:true, usuario: true},
          ],
          title: 'Riesgo',
        },

        {
          action: 'mdi-camera',
          items: [
            {title:'Aprobar imagenes Fast'      , path:'AprobarImagenesFast'                , icon:'mdi-image',            admin:true, usuario: true},
            {title:'Aprobar imagenes Inbi'      , path:'AprobarImagenesInbi'                , icon:'mdi-image',            admin:true, usuario: true},
          ],
          title: 'Imágenes',
        },

        {
          action: 'mdi-school',
          items: [
            {title:'Riesgo Teacher Fast'        , path:'riesgoxTeacherFAST'                  , icon:'mdi-clipboard-alert'  ,   admin:true, usuario: true},
            {title:'Riesgo Teacher Inbi'        , path:'riesgoxTeacherINBI'                  , icon:'mdi-clipboard-alert'  ,   admin:true, usuario: true},
            {title:'Asistencia Fast'            , path:'asistenciaTeacherFast'               , icon:'mdi-check-all'        ,   admin:true, usuario: true},
            {title:'Asistencia Inbi'            , path:'asistenciaTeacherInbi'               , icon:'mdi-check-all'        ,   admin:true, usuario: true},
            {title:'Clase iniciada Fast'        , path:'claseIniciadaFast'                   , icon:'mdi-clipboard-account',   admin:true, usuario: true},
            {title:'Clase iniciada Inbi'        , path:'claseIniciadaInbi'                   , icon:'mdi-clipboard-account',   admin:true, usuario: true},
          ],
          title: 'Teachers',
        },
      ],

    }),

    created(){
      if(this.getusuarioRiesgo.id_plantel > 0 && this.getusuarioRiesgo.id_plantel <= 6 || this.getusuarioRiesgo.id_plantel == 17){
        this.inbi = true
      }else{
        this.inbi = false
      }
      
      if(this.getusuarioRiesgo.id_plantel > 0 && this.getusuarioRiesgo.id_plantel <= 6 || this.getusuarioRiesgo.id_plantel == 17){
        this.inbi = true
        this.$vuetify.theme.themes.light.primary = '#3ACCD8'
      }else{
        this.inbi = false
        this.$vuetify.theme.themes.light.primary = '#0B2550'
      }
    },

    methods:{
      ...mapActions('login',['salirLogin']),

      salir(){
        this.salirLogin()
        // Mando a llamar la accion salir
        window.location.href = "https://englishadmin.com.mx"
      }
    },

    computed:{
      ...mapGetters('login',['getusuarioRiesgo'])
    }
  };
</script>

<style>
  html{
    background: #F4F2F2;
  }

  .v-main__wrap{
    background: #F4F2F2 !important;
  }
</style>

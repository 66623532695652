<template>
	<v-container>
	  <v-row>
	  	<v-col cols="12">
			  <v-data-table
			    :headers="headers"
			    :items="alumnos"
			    sort-by="calories"
			    class="elevation-1"
			    :search="search"
			    dense
			    :loading="loading"
			    dense
			  >

			    <template v-slot:top>
			      <v-toolbar
			        flat
			      >
			        <v-toolbar-title>Riesgo escuela Fast</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-text-field
			          label="Buscar"
			          v-model="search"
			          dense
			          hide-details
			          outlined
			        ></v-text-field>
			        <v-spacer></v-spacer>
			        <v-btn 
		            class="mr-2" 
		            small dark color="warning"
		            @click="exportar()">
		            <v-icon>save_alt</v-icon>
		          </v-btn>
			        <v-btn color="primary" small rounded @click="getDatosRiesgo()">Actualizar</v-btn>
			      </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4" lg="3" class="pb-0">
                  <v-autocomplete
                    item-value="id"
                    item-text="nombre"
                    v-model="ciclo"
                    return-object
                    :items="ciclos"
                    dense
                    outlined
                    label="Ciclos"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
			    </template>
			  </v-data-table>
  		</v-col>
	  </v-row>

    <!-- Dialogo par aindicar que se están cargando los datos -->
    <div class="text-center">
      <v-dialog
        v-model="dialogCarga"
        hide-overlay
        persistent
        width="500"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="pt-2">
            Este proceso es largo, por favor espera...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

	</v-container>
</template>

<script>
	import XLSX from 'xlsx'
	
	import {mapGetters, mapActions} from 'vuex'

  export default {
    data: () => ({
      dialogCarga:false, 

      dialogEstatus:false,
      alumnoEstatus:{},
      tipoEstatus: 0,
      mensaje:'',
      solucion:'',

    	loading:false,
    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Grupo',          value: 'grupo' },
        { text: 'Curso',          value: 'curso' },
        { text: 'Nivel',          value: 'nivel' },
        { text: 'Asistencias',    value: 'estatus' },
        { text: 'Ejercicios',     value: 'estatus_ejercicios' },
        { text: 'Examenes',       value: 'estatus_examenes' },
        { text: 'Puntos',         value: 'estatus_calificaciones'},
        { text: 'Registro',       value: 'estatus_registro'},
      ],
      alumnos: [],
      calificaciones:[],
      faltantes:[],
      ciclos:[],
      ciclo:{},
      idciclo:0,
    }),

    computed: {
    	...mapGetters('login',['getusuarioRiesgo'])
    },

    watch:{
      ciclo () {
        this.idciclo = this.ciclo.id
        // ahora que ya tenemos el ciclo, mandamos a llamar a la funcion de traer los datos del ciclo
        this.getDatosRiesgo()
      }
    },


    created () {
      // Pasp 1: Obtener los ciclos
      this.getCiclos()
    },

    methods: {
      getCiclos(){
        this.$http.get('riesgo.ciclos.fast').then(response=>{
          this.ciclos = response.body
        }).catch(error=>{
          console.log(error)
          this.loading = false
        })
      },

      getCalificaciones () {
        // creamos nuestro objeto con el idciclo
        var payload = { ciclo: this.idciclo }
        // Mandamos a traer todas las calificaciones de los alumnos y mandamos como paramtero el idciclo
        this.$http.post('riesgo.calificaciones',payload).then(response=>{
          // Mandamos a calcular las calificaciones y mandamos como parametro las calificaciones de los alumno
          //  NOTA: las calificaciones son todas, falta caluclarlas y agruparalas para sacar el proimedio de las calificaciones
          this.calcularCalificaciones(response.body)
        }).catch(error=>{
          console.log(error)
          this.loading = false
          this.dialogCarga = false
        })
      },

      calcularCalificaciones(value){
        // Creamos un arreglo de las calificaciones
        var arrayCalificaciones = value

        // Sacamos los datos repetidos
        let alumnos = value.map(item=>{
          return [item.nombre,item]
        });

        // Creamos un map de los alumnos
        var alumnosArray = new Map(alumnos); // Pares de clave y valor
        // y ahora si, extraemos los valores unicos
        let unicos = [...alumnosArray.values()]; // Conversión a un array

        // Hacemos un recorrido de todos los valores unicos
        for(const i in unicos){
          // Iniciamos todas las variables que vamos a utilizar para las sumas y conteos
          var extraordinario        = 0

          var sumaCalifEjercicio    = 0
          var cantEjercicio         = 0
          
          var sumaCalifExamen       = 0
          var cantExamen            = 0
          
          var sumaTotal             = 0
          
          var sumaAsistencia        = 0
          var cantAsistencia        = 0
          var sumaFalta             = 0
          var sumaRetardo           = 0
          var sumaJustificada       = 0
          
          var sumaCalifExamenExtra  = 0
          
          // Hacemos un recorrido para saber que datos le pertenecen a que alumno de los valores unicos
          //  Como es un recorrido, esto significa que estamos busando las calificaciones del alumno en la posición i
          for(const j in arrayCalificaciones){
            // Si el alumno es igual al que estamos buscando
            if(unicos[i].id_alumno == arrayCalificaciones[j].id_alumno){
              // Varificamos sus ejercicios
              if(arrayCalificaciones[j].tipo_evaluacion == 1 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
                // Hacemos el conteo y la suma de las calificaciones
                sumaCalifEjercicio = sumaCalifEjercicio + arrayCalificaciones[j].calificacion
                cantEjercicio = cantEjercicio + 1
              }else{
                // Si no es ejercicios es examen (normal o recuperación)
                // Exmane normal o recuperación
                if(arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==2 || arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
                  // Hamos la suma y el conteo
                  sumaCalifExamen = sumaCalifExamen + arrayCalificaciones[j].calificacion
                  cantExamen = cantExamen + 1
                }

                // También verificamos si es extraordianrio, por que también eso es posible, tener un examen extraordinario
                if(arrayCalificaciones[j].tipo_evaluacion == 3 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
                  sumaCalifExamenExtra = sumaCalifExamenExtra + arrayCalificaciones[j].calificacion
                  extraordinario = extraordinario + 1
                }
              }

            }
          }

          // Haciendo el calculo para los puntos obtenidos de asistencias
          sumaAsistencia  = unicos[i].asistencia
          sumaFalta  = unicos[i].falta
          sumaRetardo  = unicos[i].retardo
          sumaJustificada  = unicos[i].justicada
          // Sacamos los puntos de las asistencias
          var ptsAsistencia = (unicos[i].valor_asistencia / unicos[i].total_dias_laborales)
          // Hacemos la suma de los puntos
          var sumaTotalAsistencia = (sumaAsistencia * ptsAsistencia) +  (sumaRetardo * (ptsAsistencia/2))

          // Calculamos los ejercicios y los puntos por ejercicios y los puntos finales
          var divEjercico =  (sumaCalifEjercicio/unicos[i].num_ejercicios)
          var ptsEjercicio = unicos[i].valor_ejercicios/100
          var ptsFinalesEjercicios = divEjercico * ptsEjercicio

          // Calculamos los examenes y los puntos por examenes y los puntos finales
          var divExamenes =  (sumaCalifExamen/unicos[i].num_examenes)
          var ptsExamenes = unicos[i].valor_examenes/100
          var ptsFinalesExamenes = divExamenes * ptsExamenes

          // Vemos la cantidad de asistencias
          cantAsistencia = sumaAsistencia +  sumaRetardo 

          // hacemos el objeto que vamos a insertar a buscar en nuestro arreglo inicial de los alumnos
          //  para poder ahora si a cada alumno ponerle su calificacione
          var payload = {
            id_alumno:              unicos[i].id_alumno,
            total:                  (ptsFinalesEjercicios + ptsFinalesExamenes + sumaTotalAsistencia).toFixed(2),
            id_grupo:               unicos[i].id_grupo
          }

          this.calificaciones.push(payload)


        }
        // Ahora comparamos cada una de las calificaciones con la lista de alumnos que tenemos de riesgo, para ingresarle su calificación y ver si esta en riesgo o no por puntors
        for(const m in this.alumnos){
          for(const n in this.calificaciones){
            if(this.calificaciones[n].id_grupo == this.alumnos[m].id_grupo){
              console.log('siii',this.calificaciones[n].total)
              if(this.calificaciones[n].total < 35){
                this.alumnos[m].estatus_calificaciones += 1
              }
            }
          }
        }

        // for(const m in this.alumnos){
        //   for(const n in this.calificaciones){
        //     console.log(this.calificaciones[n].id_grupo)
        //     if(this.calificaciones[n].id_grupo == this.alumnos[m].id_grupo){
        //       if(this.calificaciones[n].estatus_calificaciones2 == 'ALTO'){
        //         this.alumnos[m].estatus_calificaciones += 1
        //       }
        //     }
        //   } 
        // }


        // Quitamos los loader
        this.dialogCarga = false
        this.loading     = false
      },

      // Obtener la información de los laumnos en riesgo de ese ciclo
      getDatosRiesgo () {
        // Primero traemos los que faltan por entrar a la plataforma
        this.$http.post('riesgo.alumnos.registro').then(response=>{
          // Los guardamos
          this.faltantes  = response.body
          console.log('faltantes',this.faltantes)
          // Activamos el loading de la tabla, activamos el loader especial
          this.dialogCarga = true
        	this.loading = true
          // Vaciamos nuestro arreglo de datos 
        	this.alumnos = []
          // Cramos nuestro objeto con el ciclo que vamos a traer
        	var payload = { ciclo: this.idciclo }
          // Mandamos a consultar la api de alumnos de riesgo en fast y mandamos como parametro el idciclo
      		this.$http.post('riesgo.alumnos.fast',payload).then(response=>{
            // Sacamos los datos repetidos
            var datosGenerales = response.body

            let grupos = response.body.map(item=>{
              return [item.grupo,item]
            });

            // Creamos un map de los alumnos
            var gruposArray = new Map(grupos); // Pares de clave y valor
            // y ahora si, extraemos los valores unicos
            let unicos = [...gruposArray.values()]; // Conversión a un array

            var arrayDatos = []

            for(const i in unicos){
              var object = {
                id_grupo:                unicos[i].id_grupo,
                grupo:                   unicos[i].grupo,
                estatus_registro:        0,
                estatus:                 0,
                estatus_calificaciones:  0,
                estatus_ejercicios:      0,
                estatus_examenes:        0,
                curso:                   unicos[i].curso,
                nivel:                   unicos[i].nivel

              }
              for(const j in this.faltantes){
                if(this.faltantes[j].id_grupo == unicos[i].id_grupo){
                  object.estatus_registro += 1
                }
              }

              for(const m in datosGenerales){
                if(datosGenerales[m].id_grupo == unicos[i].id_grupo){
                  if(datosGenerales[m].estatus == 'ALTO'){
                    object.estatus += 1
                  }

                  if(datosGenerales[m].estatus_ejercicios == 'ALTO'){
                    object.estatus_ejercicios += 1
                  }

                  if(datosGenerales[m].estatus_examenes == 'ALTO'){
                    object.estatus_examenes += 1
                  }
                }
              }
              arrayDatos.push(object)
            }

            console.log(arrayDatos)

            // Guardamos los alumnos obtenidos en un arreglo de objetos (alumnos)
          	this.alumnos = arrayDatos

           

          	// Ahora, hay que consultar las calificaciones de esos alumnos que están en riesgo
            this.getCalificaciones()
          }).catch(error=>{
          	console.log(error)
          	this.loading = false
          })
        }).catch(error=>{
          console.log(error)
          this.loading = false
        })
      },

      // EXCEL
      exportar(){
        let xls = {
          data:      this.alumnos,
          tHeaders : ['Codigo','Nombre', 'Sal', 'Labortorio'],
          filterVal: ['codigo','nomart', 'sal', 'lab'],
          nomfile :  "Productos"
        }
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data = XLSX.utils.json_to_sheet(this.alumnos)
        const workbook = XLSX.utils.book_new()
        const filename = 'Riesgo_General_fast'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        console.log(file)
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = this.$http.options.root + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },

    },
  }
</script>

<template>
	<v-container>
	  <v-row>
	  	<v-col cols="12">
			  <v-data-table
			    :headers="headers"
			    :items="desserts"
			    sort-by="calories"
			    class="elevation-1"
			    :search="search"
			    dense
			    :loading="loading"
			    dense
			  >
			    <template v-slot:top>
			      <v-toolbar
			        flat
			      >
			        <v-toolbar-title>Asistencias</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-text-field
			          label="Buscar"
			          v-model="search"
			          dense
			          hide-details
			          outlined
			        ></v-text-field>
			        <v-spacer></v-spacer>
			        <v-btn 
		            class="mr-2" 
		            small dark color="warning"
		            @click="exportar()">
		            <v-icon>save_alt</v-icon>
		          </v-btn>
			        <v-btn color="primary" small rounded @click="initialize()">Actualizar</v-btn>
			      </v-toolbar>
			    </template>
			  </v-data-table>
  		</v-col>
	  </v-row>
	</v-container>
</template>

<script>
	import XLSX from 'xlsx'
	
	import {mapGetters, mapActions} from 'vuex'

  export default {
    data: () => ({
    	loading:true,
    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Alumno',
          align: 'start',
          sortable: false,
          value: 'nombre',
        },
        { text: 'Grupo',          value: 'grupo' },
        { text: 'Asistencias',    value: 'asistencias' },
        { text: 'Días laborales', value: 'total_dias_laborales' },
        { text: 'Examenes',       value: 'examenes' },
        { text: 'num_examenes',   value: 'num_examenes' },
        { text: 'Actividades',    value: 'ejercicios' },
        { text: 'num_ejercicios', value: 'num_ejercicios' },
        { text: 'Total',          value: 'total' },
        { text: 'Teacher 1',      value: 'teacher' },
        { text: 'Teacher 2',      value: 'teacher2' },
      ],
      desserts: [],
    }),

    computed: {
    	...mapGetters('login',['getusuarioRiesgo'])
    },


    created () {
      this.initialize()
    },

    methods: {
      initialize () {
      	this.loading = true
      	this.desserts = []
    		this.$http.post('riesgo.alumnos.inbi').then(response=>{
        	this.calcularCalificaciones(response.body)
        }).catch(error=>{
        	console.log(error)
        	this.loading = false
        })
      },

      calcularCalificaciones(value){
      	var arrayCalificaciones = value
      	let alumnos = value.map(item=>{
			    return [item.nombre,item]
				});
				var alumnosArray = new Map(alumnos); // Pares de clave y valor

				let unicos = [...alumnosArray.values()]; // Conversión a un array
      	for(const i in unicos){
      		var extraordinario        = 0
	      	var sumaCalifEjercicio    = 0
	      	var cantEjercicio         = 0
	      	var sumaCalifExamen       = 0
	      	var cantExamen            = 0
	      	var sumaTotal             = 0
	      	var sumaAsistencia        = 0
	      	var cantAsistencia        = 0
	      	var sumaFalta             = 0
	      	var sumaRetardo           = 0
	      	var sumaCalifExamenExtra  = 0
	      	var sumaJustificada       = 0
      		for(const j in arrayCalificaciones){
      			if(unicos[i].id_alumno == arrayCalificaciones[j].id_alumno){
      				if(arrayCalificaciones[j].tipo_evaluacion == 1 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
      					sumaCalifEjercicio = sumaCalifEjercicio + arrayCalificaciones[j].calificacion
      					cantEjercicio = cantEjercicio + 1
      				}else{
      					if(arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==2 || arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
	      					sumaCalifExamen = sumaCalifExamen + arrayCalificaciones[j].calificacion
	      					cantExamen = cantExamen + 1
      					}

      					if(arrayCalificaciones[j].tipo_evaluacion == 3 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
      						sumaCalifExamenExtra = sumaCalifExamenExtra + arrayCalificaciones[j].calificacion
      						extraordinario = extraordinario + 1
      					}
      				}
      				if(unicos[i].id_alumno == 9970){
      					console.log('cantidad de ejercicios',cantEjercicio)
      				}

      			}
	      	}

	      	// Haciendo el calculo para los puntos obtenidos de asistencias
    			sumaAsistencia  = unicos[i].asistencia
					sumaFalta  = unicos[i].falta
					sumaRetardo  = unicos[i].retardo
					sumaJustificada  = unicos[i].justicada
					var ptsAsistencia = (unicos[i].valor_asistencia / unicos[i].total_dias_laborales)
					var sumaTotalAsistencia = (sumaAsistencia * ptsAsistencia) +  (sumaRetardo * (ptsAsistencia/2))

      		var divEjercico =  (sumaCalifEjercicio/unicos[i].num_ejercicios)
      		var ptsEjercicio = unicos[i].valor_ejercicios/100
      		var ptsFinalesEjercicios = divEjercico * ptsEjercicio

      		var divExamenes =  (sumaCalifExamen/unicos[i].num_examenes)
      		var ptsExamenes = unicos[i].valor_examenes/100
      		var ptsFinalesExamenes = divExamenes * ptsExamenes

      		cantAsistencia = sumaAsistencia +  sumaRetardo 

	      	var payload = {
	      		nombre:                 unicos[i].nombre,
	      		grupo:                  unicos[i].grupo,
	      		examenes:               cantExamen,
	      		num_examenes:           unicos[i].num_examenes,
	      		ejercicios:             cantEjercicio,
	      		num_ejercicios:         unicos[i].num_ejercicios,
	      		total:                  (ptsFinalesEjercicios + ptsFinalesExamenes + sumaTotalAsistencia).toFixed(2),
	      		asistencias:            unicos[i].asistencia + unicos[i].retardo,
	      		total_dias_laborales:   unicos[i].total_dias_laborales,
	      		teacher:   							unicos[i].teacher,
	      		teacher2:   						unicos[i].teacher2,
	      		telefono:   						unicos[i].telefono,
	      		celular:    						unicos[i].celular,
	      		pts_ejercicios:    			ptsFinalesEjercicios.toFixed(2),
	      		pts_asistencias:    		sumaTotalAsistencia.toFixed(2),
	      		pts_examenes:    				ptsFinalesExamenes.toFixed(2),
	      		ptsx_ejercicios:    	  ptsEjercicio,
	      		ptsx_asistencias:    	  ptsAsistencia,
	      		ptsx_examenes:    			ptsExamenes,
	      		extraodinario:          extraordinario,
	      		califextra:             sumaCalifExamenExtra,
	      		id_curso:               unicos[i].id_curso,
	      	}

	      	this.desserts.push(payload)
      	}
      	this.loading = false
      },

      // EXCEL
      exportar(){
        let xls = {
          data:      this.desserts,
          tHeaders : ['Codigo','Nombre', 'Sal', 'Labortorio'],
          filterVal: ['codigo','nomart', 'sal', 'lab'],
          nomfile :  "Productos"
        }
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data = XLSX.utils.json_to_sheet(this.desserts)
        const workbook = XLSX.utils.book_new()
        const filename = 'Riesgo_General_inbi'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        console.log(file)
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*Initialize the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = this.$http.options.root + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },
    },
  }
</script>

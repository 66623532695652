var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mx-auto text-center"},[_c('p',{staticClass:"ml-2 mt-4 title text-sm-left text-md-left"},[_vm._v(" Imágenes pendientes por aprobar en FAST ")]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),(_vm.imagenes.length == 0)?_c('v-card-text',[_vm._v(" No hay imágenes ")]):_vm._e(),_c('v-row',{staticClass:"pa-2"},[_vm._l((_vm.imagenes),function(imagen,i){return _c('v-col',{key:imagen.idimagenes_grupo,staticClass:"d-flex child-flex",attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":_vm.link + imagen.archivo,"lazy-src":_vm.link + imagen.archivo,"aspect-ratio":"1","height":"200"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out  v-card--reveal text-h2 white--text",staticStyle:{"height":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteImagen(imagen.idimagenes_grupo)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":"","dark":""}},[_vm._v(" mdi-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar imagen")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.actualizarArchivos(imagen.idimagenes_grupo)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":"","dark":""}},[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Aceptar imagen")])])],1):_vm._e()])],1)]}}],null,true)})],1)}),_c('v-dialog',{staticClass:"mx-auto",attrs:{"max-width":"500"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("¿Estás seguro de eliminar esta imagen? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDelete()}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<v-container>
	  <v-row>
	  	<v-col cols="12">
			  <v-data-table
			    :headers="headers"
			    :items="desserts"
			    sort-by="calories"
			    class="elevation-1"
			    :search="search"
			    dense
			  >	
			  	<template v-slot:item.estatus="{ item }">
            <v-chip
              color="green"
              dark
              v-if="item.total >= 70 || item.extra >= 70"
              small
            >
              Aprobado
            </v-chip>

            <v-chip
              color="red"
              dark
              v-else
              small
            >
              Reprobado
            </v-chip>
          </template>

			    <template v-slot:top>
			      <v-toolbar
			        flat
			      >
			        <v-toolbar-title>Calificaciones</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-text-field
			          label="Buscar"
			          v-model="search"
			          dense
			          hide-details
			          outlined
			        ></v-text-field>
			        <v-spacer></v-spacer>

			        <v-btn color="primary" small rounded @click="initialize()">Actualizar</v-btn>
			      </v-toolbar>
			      <v-card-text>
              <v-row>
                <v-col cols="12" md="4" lg="3" class="pb-0">
                  <v-autocomplete
                    item-value="id"
                    item-text="nombre"
                    v-model="ciclo"
                    return-object
                    :items="ciclos"
                    dense
                    outlined
                    label="Ciclos"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
			    </template>
			  </v-data-table>
  		</v-col>
	  </v-row>

	  <!-- Dialogo par aindicar que se están cargando los datos -->
    <div class="text-center">
      <v-dialog
        v-model="dialogCarga"
        hide-overlay
        persistent
        width="500"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="pt-2">
            Cargando los datos...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

	</v-container>
</template>

<script>

	import {mapGetters, mapActions} from 'vuex'

  export default {
    data: () => ({
    	dialogCarga:false,
    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Alumno',
          align: 'start',
          sortable: false,
          value: 'nombre',
        },
        { text: 'Grupo',          value: 'grupo' },
        { text: 'Asistencias',    value: 'asistencias' },
        { text: 'Días laborales', value: 'total_dias_laborales' },
        { text: 'Examenes',       value: 'examenes' },
        { text: 'num_examenes',   value: 'num_examenes' },
        { text: 'Actividades',    value: 'ejercicios' },
        { text: 'num_ejercicios', value: 'num_ejercicios' },
        { text: 'Total',          value: 'total' },
        { text: 'Extraordinario', value: 'extra' },
        { text: 'Estatus'       , value: 'estatus' },
      ],
      desserts: [],
      ciclos:[],
      ciclo:{},
      idciclo:0,
    }),

    watch:{
      ciclo () {
        this.idciclo = this.ciclo.id
        // ahora que ya tenemos el ciclo, mandamos a llamar a la funcion de traer los datos del ciclo
        // this.getDatosRiesgo()
        // Primero traemos los grupos del teacher
        this.dialogCarga = true
        this.initialize()
      }
    },

    computed: {
    	...mapGetters('login',['getusuarioRiesgo'])
    },


    created () {
      this.getCiclos()
    },

    methods: {
    	getCiclos(){
      	if(this.getusuarioRiesgo.id_plantel > 0 && this.getusuarioRiesgo.id_plantel <= 6 || this.getusuarioRiesgo.id_plantel == 17){
      		this.$http.get('riesgo.ciclos.inbi').then(response=>{
	          this.ciclos = response.body
	        }).catch(error=>{
	          console.log(error)
	          this.loading = false
	        })
      	}else{
	        this.$http.get('riesgo.ciclos.fast').then(response=>{
	          this.ciclos = response.body
	        }).catch(error=>{
	          console.log(error)
	          this.loading = false
	        })
      	}
      },

      initialize () {
      	this.desserts = []
      	// inbi
      	var payload = {
      		id_plantel: this.getusuarioRiesgo.id_plantel,
      		ciclo: this.idciclo
      	}
      	if(this.getusuarioRiesgo.id_plantel > 0 && this.getusuarioRiesgo.id_plantel <= 6 || this.getusuarioRiesgo.id_plantel == 17){
	    		this.$http.post('calificaciones.alumnos.inbi',payload).then(response=>{
	    			this.dialogCarga = true
	        	this.calcularCalificaciones(response.body)
	        }).catch(error=>{

	        })
	    		// Fast
    		}else{
    			this.$http.post('calificaciones.alumnos',payload).then(response=>{
    				this.dialogCarga = true
	        	this.calcularCalificaciones(response.body)
	        }).catch(error=>{

	        })
    		}
      },

      calcularCalificaciones(value){
      	var arrayCalificaciones = value
      	let alumnos = value.map(item=>{
			    return [item.nombre,item]
				});
				var alumnosArray = new Map(alumnos); // Pares de clave y valor

				let unicos = [...alumnosArray.values()]; // Conversión a un array
      	for(const i in unicos){
	      	var sumaCalifEjercicio = 0
	      	var cantEjercicio      = 0
	      	var sumaCalifExamen    = 0
	      	var cantExamen         = 0
	      	var sumaTotal          = 0
	      	var sumaAsistencia     = 0
	      	var cantAsistencia     = 0
	      	var sumaFalta          = 0
	      	var sumaRetardo        = 0
	      	var sumaJustificada    = 0
	      	var califExtra = ''
      		for(const j in arrayCalificaciones){
      			if(unicos[i].id_alumno == arrayCalificaciones[j].id_alumno){
      				if(arrayCalificaciones[j].tipo_evaluacion == 1 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
      					sumaCalifEjercicio = sumaCalifEjercicio + arrayCalificaciones[j].calificacion
      					cantEjercicio = cantEjercicio + 1
      				}else{
      					if(arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==1 ||arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==2 ){
	      					sumaCalifExamen = sumaCalifExamen + arrayCalificaciones[j].calificacion
	      					cantExamen = cantExamen + 1
	      				}else{
	      					califExtra = arrayCalificaciones[j].calificacion
	      				}
      				}
      			}

	      	}

	      	// Haciendo el calculo para los puntos obtenidos de asistencias
    			sumaAsistencia  = unicos[i].asistencia
					sumaFalta  = unicos[i].falta
					sumaRetardo  = unicos[i].retardo
					sumaJustificada  = unicos[i].justificada

					var ptsAsistencia = (unicos[i].valor_asistencia / unicos[i].total_dias_laborales)
					var sumaTotalAsistencia = (sumaAsistencia * ptsAsistencia) +  (sumaRetardo * (ptsAsistencia/2)  + (sumaJustificada * ptsAsistencia/2))

					// Calulando los puntos por ejercicios
      		var divEjercico =  (sumaCalifEjercicio/unicos[i].num_ejercicios)

      		var ptsEjercicio = unicos[i].valor_ejercicios/100
      		var ptsFinalesEjercicios = divEjercico * ptsEjercicio

      		var divExamenes =  (sumaCalifExamen/unicos[i].num_examenes)
      		var ptsExamenes = unicos[i].valor_examenes/100
      		var ptsFinalesExamenes = divExamenes * ptsExamenes

      		cantAsistencia = sumaAsistencia +  sumaRetardo 

      		// Puntos de los ejercicios
      		var ejerciciosFaltante = unicos[i].num_ejercicios - cantEjercicio
      		var ptsDispoEjercicio  = ejerciciosFaltante * (unicos[i].valor_ejercicios/unicos[i].num_ejercicios)

      		// Pts de los examenes
      		var examenesFaltante = unicos[i].num_examenes - cantExamen
      		var ptsDispoExamen  = examenesFaltante * (unicos[i].valor_examenes/unicos[i].num_examenes)

      		// Puntos de las asistencias
      		var asistenciasFaltante = unicos[i].total_dias_laborales - cantAsistencia
      		var ptsDispoAsis  = asistenciasFaltante * (unicos[i].valor_asistencia/unicos[i].total_dias_laborales)

      		// Puntos finales
      		var ptsDispoFinales = ptsDispoEjercicio + ptsDispoExamen + ptsDispoAsis


	      	var payload = {
	      		nombre:                 unicos[i].nombre,
	      		grupo:                  unicos[i].grupo,
	      		examenes:               cantExamen,
	      		num_examenes:           unicos[i].num_examenes,
	      		ejercicios:             cantEjercicio,
	      		num_ejercicios:         unicos[i].num_ejercicios,
	      		total:                  (ptsFinalesEjercicios + ptsFinalesExamenes + sumaTotalAsistencia).toFixed(2),
	      		asistencias:            cantAsistencia,
	      		total_dias_laborales:   unicos[i].total_dias_laborales,
	      		pts_finales:            ptsDispoFinales.toFixed(2),
	      		telefono:               unicos[i].telefono,
	      		celular:                unicos[i].celular,
	      		extra:                  califExtra,
	      		ptsDispoEjercicio:      ptsFinalesEjercicios,
	      		ptsDispoExamen:         ptsFinalesExamenes,
	      		ptsDispoAsis:           sumaTotalAsistencia,

	      	}

	      	if(this.getusuarioRiesgo.idplanteles.includes(unicos[i].id_plantel )){
	      		this.desserts.push(payload)
	      	}

      	}
      	this.dialogCarga = false
      },
    },
  }
</script>

<template>
	<v-container fluid>
	  <v-row>
	  	<v-col cols="12">
			  <v-data-table
			    :headers="headers"
			    :items="alumnos"
			    sort-by="calories"
			    class="elevation-1"
			    :search="search"
			    dense
			    :loading="loading"
			    dense
			  >
			    <template v-slot:top>
			      <v-card-title flat>
			        <v-toolbar-title>Riespo pts Fast</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-text-field
			          label="Buscar"
			          v-model="search"
			          dense
			          hide-details
			          outlined
			        ></v-text-field>
			        <v-spacer></v-spacer>
			        <v-btn 
		            class="mr-2" 
		            small dark color="warning"
		            @click="exportar()">
		            <v-icon>save_alt</v-icon>
		          </v-btn>
			        <v-btn color="primary" small rounded @click="initialize()">Actualizar</v-btn>
			      </v-card-title>
			      <v-card-text>
              <v-row>
                <v-col cols="12" md="4" lg="3" class="pb-0">
                  <v-autocomplete
                    item-value="id"
                    item-text="nombre"
                    v-model="ciclo"
                    return-object
                    :items="ciclos"
                    dense
                    outlined
                    label="Ciclos"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
			    </template>
			  </v-data-table>
  		</v-col>
	  </v-row>
	</v-container>
</template>

<script>
	import XLSX from 'xlsx'
	
	import {mapGetters, mapActions} from 'vuex'

  export default {
    data: () => ({
    	ciclos:[],
      ciclo:{},
      idciclo:0,

    	loading:false,
    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
      	{ text: 'Matricula',      value: 'matricula' },
        {
          text: 'Alumno',
          align: 'start',
          sortable: false,
          value: 'nombre',
        },
        { text: 'Grupo',          value: 'grupo' },
        { text: 'Asistencias',    value: 'asistencias' },
        { text: 'Días laborales', value: 'total_dias_laborales' },
        { text: 'Examenes',       value: 'examenes' },
        { text: 'num_examenes',   value: 'num_examenes' },
        { text: 'Actividades',    value: 'ejercicios' },
        { text: 'num_ejercicios', value: 'num_ejercicios' },
        { text: 'Total',          value: 'total' },
        { text: 'Extraordinario', value: 'extra' },
        { text: 'Pts Dispo Ejer', value: 'pts_finales' },
        { text: '1era Kardex'   , value: 'primera' },
        { text: '2da  Kardex'   , value: 'segunda' }
      ],
      alumnos: [],
    }),

    watch:{
      ciclo () {
        this.idciclo = this.ciclo.id
        // ahora que ya tenemos el ciclo, mandamos a llamar a la funcion de traer los datos del ciclo
        this.initialize()
      }
    },

    computed: {
    	...mapGetters('login',['getusuarioRiesgo'])
    },


    async created () {
      await this.getCiclos()
    },

    methods: {
    	getCiclos(){
        return this.$http.get('riesgo.ciclos.fast').then(response=>{
          this.ciclos = response.body
        }).catch(error=>{
          console.log(error)
          this.loading = false
        })
      },

      initialize () {
      	this.loading = true
      	this.alumnos = []
      	var payload = { ciclo: this.idciclo }
    		this.$http.post('riesgo.alumnos', payload).then(response=>{
        	this.calcularCalificaciones(response.body)
        }).catch(error=>{
        	console.log(error)
        	this.loading = false
        })
      },

      calcularCalificaciones(value){
      	var arrayCalificaciones = value

      	let alumnos = value.map(item=>{
			    return [item.nombre,item]
				});
				var alumnosArray = new Map(alumnos); // Pares de clave y valor

				let unicos = [...alumnosArray.values()]; // Conversión a un array
      	for(const i in unicos){

	      	var sumaCalifEjercicio = 0
	      	var cantEjercicio      = 0
	      	var sumaCalifExamen    = 0
	      	var cantExamen         = 0
	      	var sumaTotal          = 0
	      	var sumaAsistencia     = 0
	      	var cantAsistencia     = 0
	      	var sumaFalta          = 0
	      	var sumaRetardo        = 0
	      	var sumaJustificada    = 0
	      	var califExtra = ''
      		for(const j in arrayCalificaciones){
      			if(unicos[i].id_alumno == arrayCalificaciones[j].id_alumno){
      				if(arrayCalificaciones[j].tipo_evaluacion == 1 && arrayCalificaciones[j].idCategoriaEvaluacion==1){
      					sumaCalifEjercicio = sumaCalifEjercicio + arrayCalificaciones[j].calificacion
      					cantEjercicio = cantEjercicio + 1
      				}else{
      					if(arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==1 ||arrayCalificaciones[j].tipo_evaluacion == 2 && arrayCalificaciones[j].idCategoriaEvaluacion==2 ){
	      					sumaCalifExamen = sumaCalifExamen + arrayCalificaciones[j].calificacion
	      					cantExamen = cantExamen + 1
	      				}else{
	      					califExtra = arrayCalificaciones[j].calificacion
	      				}
      				}
      			}
	      	}

	      	// Haciendo el calculo para los puntos obtenidos de asistencias
    			sumaAsistencia   = unicos[i].asistencia
					sumaFalta        = unicos[i].falta
					sumaRetardo      = unicos[i].retardo
					sumaJustificada  = unicos[i].justificada

					var ptsAsistencia       = (unicos[i].valor_asistencia / unicos[i].total_dias_laborales)
					var sumaTotalAsistencia = (unicos[i].asistencia * ptsAsistencia) +  (unicos[i].retardo * (ptsAsistencia/2)) 

					// Calulando los puntos por ejercicios
      		var divEjercico          =  (sumaCalifEjercicio/unicos[i].num_ejercicios)
      		var ptsEjercicio         = unicos[i].valor_ejercicios/100
      		var ptsFinalesEjercicios = divEjercico * ptsEjercicio

      		var divExamenes        =  (sumaCalifExamen/unicos[i].num_examenes)
      		var ptsExamenes        = unicos[i].valor_examenes/100
      		var ptsFinalesExamenes = divExamenes * ptsExamenes

      		cantAsistencia = sumaAsistencia +  sumaRetardo 

      		// Puntos de los ejercicios
      		var ejerciciosFaltante = unicos[i].num_ejercicios - cantEjercicio
      		var ptsDispoEjercicio  = ejerciciosFaltante * (unicos[i].valor_ejercicios/unicos[i].num_ejercicios)

      		// Pts de los examenes
      		var examenesFaltante = unicos[i].num_examenes - cantExamen
      		var ptsDispoExamen  = examenesFaltante * (unicos[i].valor_examenes/unicos[i].num_examenes)

      		// Puntos de las asistencias
      		var asistenciasFaltante = unicos[i].total_dias_laborales - cantAsistencia
      		var ptsDispoAsis  = asistenciasFaltante * (unicos[i].valor_asistencia/unicos[i].total_dias_laborales)

      		// Puntos finales
      		var ptsDispoFinales = ptsDispoEjercicio + ptsDispoExamen + ptsDispoAsis


	      	var payload = {
	      		nombre:                 unicos[i].nombre,
	      		matricula:              unicos[i].matricula,
	      		grupo:                  unicos[i].grupo,
	      		examenes:               cantExamen,
	      		num_examenes:           unicos[i].num_examenes,
	      		ejercicios:             cantEjercicio,
	      		num_ejercicios:         unicos[i].num_ejercicios,
	      		total:                  (ptsFinalesEjercicios + ptsFinalesExamenes + sumaTotalAsistencia).toFixed(2),
	      		asistencias:            cantAsistencia,
	      		total_dias_laborales:   unicos[i].total_dias_laborales,
	      		pts_finales:            ptsDispoFinales.toFixed(2),
	      		telefono:               unicos[i].telefono,
	      		celular:                unicos[i].celular,
	      		extra:                  califExtra,
	      		ptsDispoEjercicio:      ptsFinalesEjercicios,
	      		ptsDispoExamen:         ptsFinalesExamenes,
	      		ptsDispoAsis:           sumaTotalAsistencia,
	      		teacher:                '',
	      		teacher2:               '',
	      		primera:                unicos[i].calificacion_final_primera_oportunidad,
	      		segunda:                unicos[i].calificacion_final_segunda_oportunidad,
	      	}

	      	this.alumnos.push(payload)
      	}
      	this.loading = false
      },

      // EXCEL
      exportar(){
        let xls = {
          data:      this.alumnos,
          tHeaders : ['Codigo','Nombre', 'Sal', 'Labortorio'],
          filterVal: ['codigo','nomart', 'sal', 'lab'],
          nomfile :  "Productos"
        }
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data = XLSX.utils.json_to_sheet(this.alumnos)
        const workbook = XLSX.utils.book_new()
        const filename = 'puntos_dispo_fast'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        console.log(file)
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*Initialize the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = this.$http.options.root + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },
    },
  }
</script>

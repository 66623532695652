<template>
  <v-container>
    <v-card class="mx-auto text-center">
      <p class="ml-2 mt-4 title text-sm-left text-md-left">
        Imágenes pendientes por aprobar en INBI
      </p>
      <v-spacer></v-spacer>

      <v-snackbar v-model="snackbar">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            <v-icon dark>
              mdi-close
            </v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-card-text v-if="imagenes.length == 0">
        No hay imágenes
      </v-card-text>

      <v-row class="pa-2">
        <v-col
          v-for="(imagen, i) in imagenes"
          :key="imagen.idimagenes_grupo"
          class="d-flex child-flex"
          cols="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          ><v-hover v-slot="{ hover }">
            <v-img
              :src="link + imagen.archivo"
              :lazy-src="link + imagen.archivo"
              aspect-ratio="1"
              class="grey lighten-2"
              height="200"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out  v-card--reveal text-h2 white--text"
                  style="height: 100%;"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="deleteImagen(imagen.idimagenes_grupo)"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon large dark>
                          mdi-minus
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar imagen</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="actualizarArchivos(imagen.idimagenes_grupo)"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon large dark>
                          mdi-check
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Aceptar imagen</span>
                  </v-tooltip>
                </div>
              </v-expand-transition>
            </v-img>
          </v-hover>
        </v-col>
        <v-dialog v-model="dialogDelete" class="mx-auto" max-width="500">
          <v-card>
            <v-card-title class="text-h6"
              >¿Estás seguro de eliminar esta imagen?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete()"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
var moment = require("moment");
moment.locale();

import router from "@/router";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    link: "",

    //dialogs
    dialog: false,
    dialogDelete: false,

    text: "",
    snackbar: false,

    imagenes: [],

    id: 0,
  }),

  computed: {
    ...mapGetters("login", ["getusuarioRiesgo"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    const fecha = new Date().toLocaleDateString("en-Es", {
      timeZone: "America/Mexico_City",
    });
    this.fecha = moment().format("YYYY-MM-DD HH:mm:ss");

    //this.link = "http://localhost:3002/" + "filesInbi/"; //apuntar a la apidel lms https://web-back-inbi.club/
    this.link = "https://web-back-inbi.club/herramientas-api/filesInbi/";
  },

  methods: {
    ...mapActions("login", [
      "validarUser",
      "guardarInfo",
      "GetInfoUser",
      "salirLogin",
    ]),

    initialize() {
      this.$http
        .get("archivos.inbi.grupo.all/")
        .then((response) => {
          // al arreglo que declare previamente le asigno response.body que son todos los registros
          this.imagenes = response.body;
        })
        .catch((error) => console.log(error));
    },

    //aprobar imagen
    actualizarArchivos(id) {
      //
      var archivos = {
        usuario_reviso_erp: this.getusuarioRiesgo.id_usuario,
        aprobada: 1,
        fecha_actualizo: this.fecha,
        deleted: 0,
      };

      this.$http
        .put("archivos.inbi.grupo.update/" + id, archivos)
        .then((response) => {
          this.snackbar = true;
          this.text = "La imagen se aprobó correctamente.";
          this.initialize();
        })
        .catch((error) => console.log(error));
    },

    //eliminar imagen
    deleteConfirm() {
      var archivos = {
        usuario_reviso_erp: this.getusuarioRiesgo.id_usuario,
        aprobada: 0,
        fecha_actualizo: this.fecha,
        deleted: 1,
      };

      this.$http
        .put("archivos.inbi.grupo.update/" + this.id, archivos)
        .then((response) => {
          this.snackbar = true;
          this.text = "La imagen se eliminó correctamente.";
          this.initialize();
        })
        .catch((error) => console.log(error));

      this.closeDelete();
    },

    //abrir dialogo
    deleteImagen(id) {
      this.dialogDelete = true;
      this.id = id;
    },

    //cerrar dialogo
    closeDelete() {
      this.dialogDelete = false;
      this.id = 0;
      this.initialize();
    },

    //procesar la fecha
    moment(date) {
      return moment(date);
    },
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;

  position: absolute;
  width: 100%;
}
::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

::-webkit-scrollbar:vertical {
  width: 8px !important;
}

::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 8px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #babcbf;
  border-radius: 20px;
  border: 1px solid #ededed;
}
</style>

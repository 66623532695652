<template>
  <v-container>
    <div id="pagar" class="cho-container"></div>
  </v-container>
</template>
<script>
  export default{

    mounted() {
      const mp = new MercadoPago('TEST-5a054ef7-cd86-4517-9841-5ede8fb6705e', {
        // locale: 'es-Mx'
      });

      // // Inicializa el checkout
      mp.checkout({
        preference: {
          id: '822014957-7bc2d511-f3fd-4ae3-842b-34eb5a31659d'
        },
        render: {
          container: '.cho-container', // Indica el nombre de la clase donde se mostrará el botón de pago
          label: 'Pagar', // Cambia el texto del botón de pago (opcional)
        }
      });
    }

  }


</script>
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';
import colors from 'vuetify/lib/util/colors'
import light from './theme'


Vue.use(Vuetify);

const vuetify = new Vuetify({
	lang:{
    locales:{ es },
    current: 'es'
	},
	
  theme: {
    themes: {
      light: {
        primary: '#0B2550',
        secondary: '#b0bec5',
        anchor: '#8c9eff',
      },
    },
  },
})

export default vuetify

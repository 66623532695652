<template>
	<v-container>
	  <v-row>
	  	<v-col cols="12">
			  <v-data-table
			    :headers="headers"
			    :items="asistencias"
			    class="elevation-1"
			    height="500"
			    fixed-header
			    hide-default-footer
			    :items-per-page="asistencias.length"
			    :search="search"
			    :loading="loading"
    			loading-text="Cargando... Espere un momento"
    			dense
    			sort-by="estatus_llamada"
			  >
			    <template v-slot:item.estatus_llamada="{ item }">
			      <v-chip
			        color="grey lighten-1"
			        dark
			        v-if="item.estatus_llamada==0"
			        small
			      >
			        Falta
			      </v-chip>

			      <v-chip
			        color="info"
			        v-if="item.estatus_llamada==1"
			        small
			      >
			        Sin Contacto
			      </v-chip>

			      <v-chip
			        color="green"
			        dark
			        v-if="item.estatus_llamada==2"
			        small
			      >
			        Contactado
			      </v-chip>

			      <v-chip
			        color="yellow"
			        v-if="item.estatus_llamada==3"
			        small
			      >
			        Pte. de revisión
			      </v-chip>

			      <v-chip
			        color="black"
			        dark
			        v-if="item.estatus_llamada==4"
			        small
			      >
			        Rechazado
			      </v-chip>
			    </template>

			    <template v-slot:item.llamar="{ item }">
			    	<v-btn 
			    		color="blue"
			    		dark
			    		small
			    		icon
			    		@click="abrirInfoAlumno(item), dialogWha = false, mensajeW = '', dialogTel = false, intentos = 0"
			    	>
			    		<v-icon>mdi-phone</v-icon>
			    	</v-btn>
			    </template>

			    <template v-slot:top>
			      <v-toolbar
			        flat
			      >
			        <v-toolbar-title>Inasistencias</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-text-field
			          v-model="search"
			          label="Buscar"
			          outlined
			          dense
			          hide-details
			        ></v-text-field>
			        <v-spacer></v-spacer>
			        <v-btn color="secondary" small rounded @click="init()">Actualizar</v-btn>
			      </v-toolbar>

			      <v-card-text>
              <v-row>
                <v-col cols="12" md="4" lg="3" class="pb-0">
                  <v-autocomplete
                    item-value="id"
                    item-text="nombre"
                    v-model="ciclo"
                    return-object
                    :items="ciclos"
                    dense
                    outlined
                    label="Ciclos"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
			    </template>
			  </v-data-table>
	  	</v-col>
	  </v-row>

	  <v-row justify="center">
	    <v-dialog
	    	persistent
	      v-model="dialog"
	      max-width="600"
	    >
	      <!-- persistent -->
	      <v-card
			    class="mx-auto"
			  >
			    <v-card-title class="text-h6 font-weight-regular justify-space-between">
	         {{ alumno.nombre }}
	         <v-spacer></v-spacer>
	         <v-btn color="dark" dark small  class="text-capitalize mr-2" @click="dialogTicket=true">Agregar ticket</v-btn>
			    </v-card-title>
			    <v-card-text class="py-1">
        		<b>{{alumno.grupo}}</b><br/>
			    </v-card-text>

			    <v-window v-model="step">
			    	<!-- Aqui es la opción principal de la llamada -->
			      <v-window-item :value="1">
			        <v-card-text class="pt-0">
			          <!-- Lamada por teléfono -->
					      <!-- El boton de llamar solo se activa si no se ha realziado ningun intento de llamada -->
					      <!-- El botón se oculta en caso de que inicie la llamada -->
					      <!-- dialogLlamada          = 'Hay llamada en curso' -->
					      <!-- dialogConfirmaContacto = 'Falta confirmación del usuario de que si hubo o no hubo contacto' -->
			       		<div v-if="!dialogLlamada && !dialogConfirmaContacto && contacto == 0" class="mt-2">
					        <v-card
					        	width="100%"
						        color="primary"
						        dark
						        class="elevation-0 mt-2"
						      >
						        <v-card-text class="py-1">
						          <v-btn 
								       	color="blue darken-1" 
								       	dark 
								       	@click="Call(),dialogTel = true" 
								       	small 
								       	block
								       	class="text-capitalize my-2"
						       		>
						       			<v-icon left>mdi-phone</v-icon>
						       			Llamar
						       		</v-btn>
						        </v-card-text>
						      </v-card>
					       </div>

			       		<div v-if="dialogTel" class="mt-2">
					        <v-card
					        	width="100%"
					        	v-if="dialogLlamada"
						        color="primary"
						        dark
						        class="elevation-0 mt-2"
						      >
						        <v-card-text class="py-0">
						          Llamando
						          <v-progress-linear
						            indeterminate
						            color="white"
						            class="mb-0"
						          ></v-progress-linear>
						        </v-card-text>
						        <v-card-text class="py-1">
						          <v-btn  color="error" block small id="btn_call" @click="Stoped(), dialogLlamada = false,dialogConfirmaContacto= true">Colgar</v-btn>
						        </v-card-text>
						      </v-card>
					      </div>

					      <!-- Cuaro de dialogo para saber si hubo o no hubo contacto con el alumno y se brindo un buen motivo para  -->
					      <div v-if="dialogConfirmaContacto" class="mt-2">
					        <v-card>
						        <v-card-title class="text-h5">
						        	<v-spacer></v-spacer>
						          ¿Tuviste contácto con el alumno?
						        	<v-spacer></v-spacer>
						        </v-card-title>
						        <v-card-text>Si lograste tener contácto con el alumno, por favor ingresa el motivo de su falta, recuerda que es muy importante tener un motivo valido de la inasistencia </v-card-text>
						        <v-card-actions>
						          <v-btn
						          	class="text-capitalize"
						            color="green darken-1"
						            small
						            rounded
						            dark
						            @click="contacto = 1,dialogConfirmaContacto=false,step=2"
						          >
						            Sí, hubo contácto
						          </v-btn>
						          <v-spacer></v-spacer>
						          <v-btn
						          	class="text-capitalize"
						            color="red darken-1"
						            small
						            rounded
						            dark
						            @click="contacto = 2,dialogConfirmaContacto=false, step = 5"
						          >
						            No, no hubo contácto
						          </v-btn>
						        </v-card-actions>
						      </v-card>
					      </div>

					      <!-- Escribir las especificaciones del motivo -->
					      <div v-if="contacto < 2 && dialogTel == true && !dialogConfirmaContacto" class="mt-2">
					      	Escribe aquí el motivo de su inasistencia, recuerda que tiene que ser un motivo valido de su inasistencia, o el motivo será rechazado
					      </div>
					      <!-- Ocultar la nota para cuando no haya un contacto con el alumno -->
			        	<v-textarea
			        		v-if="contacto < 2 && dialogTel == true && !dialogConfirmaContacto" 
			        		v-model="nota" 
			        		outlined 
			        		dense 
			        		hide-details 
			        		label="Motivo de falta" 
			        		class="mt-2 "
		        		></v-textarea>
			        </v-card-text>
			      </v-window-item>

			      <!-- Escribir el motivo dde la falta -->
			      <v-window-item :value="2">
			        <v-card-text>
			        	<div class="pt-2" v-if="contacto < 2 && dialogTel == true && !dialogConfirmaContacto">
			        		Escribe aquí el motivo de su inasistencia, recuerda que tiene que ser un motivo valido de su inasistencia, o el motivo será rechazado
			        	</div>
			          <!-- Ocultar la nota para cuando no haya un contacto con el alumno -->
			        	<v-textarea
			        		v-if="contacto < 2 && dialogTel == true && !dialogConfirmaContacto" 
			        		v-model="nota" 
			        		outlined 
			        		dense 
			        		hide-details 
			        		label="Motivo de falta" 
			        		class="mt-2 "
		        		></v-textarea>
			        </v-card-text>
			      </v-window-item>

			      <!-- Este step es para que la recepcionista pueda ver el motivo del por que se rechazo su motivo -->
			      <v-window-item :value="3">
			        <v-card-text>
			          <v-textarea
			          	class="mt-2"
			          	rows="3"
			        		v-model="historialAlumno.motivo_rechazo" 
			        		outlined 
			        		dense 
			        		hide-details 
			        		label="Motivo de falta" 
		        		></v-textarea>
			        </v-card-text>
			        <v-card-actions class="pt-0">
			        	<v-spacer></v-spacer>
			          <v-btn 
					      	v-if="!dialogLlamada && !dialogConfirmaContacto && contacto == 0"
					       	color="blue darken-1" 
					       	dark 
					       	@click="volveraLlamar()" 
					       	class="text-capitalize mt-2"
			       		>
			       			<v-icon left>mdi-phone</v-icon>
			       			Llamar al alumno
			       		</v-btn>
			        </v-card-actions>			
			      </v-window-item>

			      <!-- Este step es para que la recepcionista pueda ver el motivo del por que se rechazo su motivo -->
			      <v-window-item :value="4">
			        <v-card-text>
			        	<div class="pt-2" v-if="contacto < 2 && dialogTel == true && !dialogConfirmaContacto">
			        		Escribe aquí el motivo de su inasistencia, recuerda que tiene que ser un motivo valido de su inasistencia, o el motivo será rechazado
			        	</div>
			        	<div>El alumno fue contáctado por Whatsapp, ingresa la respuesta que te mando el alumno, en caso de no tener respuesta puedes volverle a llamar</div>
			        	<v-textarea
			        		v-model="nota" 
			        		outlined 
			        		dense 
			        		hide-details 
			        		label="Motivo de falta" 
			        		class="mt-2 "
		        		></v-textarea>
			        </v-card-text>
			        <v-card-actions class="pt-0">
			        	<v-spacer></v-spacer>
			        	<v-btn
			          	class="text-capitalize"
			            color="green"
			            dark
			            @click="llamada()"
			          >
			            Enviar motivo
			          </v-btn>
			          <v-btn 
					      	v-if="!dialogLlamada && !dialogConfirmaContacto && contacto == 0"
					       	color="blue darken-1" 
					       	dark 
					       	@click="volveraLlamar()" 
			       		>
			       			<v-icon left>mdi-phone</v-icon>
			       			Volver a Llamar
			       		</v-btn>
			        </v-card-actions>			
			      </v-window-item>

			      <!-- No no hubo contacto, opcion de whatssapp o volver a llamar -->
			      <v-window-item :value="5">
			        <v-card-text>
			        	<!-- El boton de whatsapp y el de volver a llamar se debe activar despues de confirmar si hubo o no hubo contacto con el usuario -->
					      <!-- WhatasApp -->
					      <v-card-actions>
					        <v-spacer></v-spacer>
				        	<v-btn 
						        v-if="contacto == 2"
				        		color="success" 
				        		@click="mensajeWhatsapp(),tipoMedioContacto = 1" 
				        		small 
				        		rounded 
				        		class="text-capitalize mt-2 mr-2"
			        		  >
			        			<v-icon left>mdi-whatsapp</v-icon>
			        			Mandar mensaje
			        		</v-btn>

					        <v-spacer></v-spacer>

			        		<!-- Lammada -->
			        		<v-btn 
						      	v-if="contacto == 2"
						       	color="blue darken-1" 
						       	dark 
						       	@click="volveraLlamar()" 
						       	small 
						       	rounded 
						       	class="text-capitalize mt-2"
				       		  >
				       			<v-icon left>mdi-phone</v-icon>
				       			Volver a llamar
				       		</v-btn>
					        
					        <v-spacer></v-spacer>
					      </v-card-actions>

		        		<v-alert
		        			dense
		        			class="mt-2"
		        			v-if="errorWhatsapp"
						      prominent
						      type="error"
						      >
						      <v-row align="center">
						        <v-col class="grow">
						          El número {{alumno.celular}} no concuerda con un número de whatsapp
						        </v-col>
						        <v-col class="shrink">
						          <v-btn @click="errorWhatsapp = false">Ok</v-btn>
						        </v-col>
						      </v-row>
						    </v-alert>

					      <div v-if="dialogWha" class="mt-2">
				        	<v-textarea 
				        		v-model="mensajeW" 
				        		outlined 
				        		dense 
				        		hide-details 
				        		label="Mensaje"
			        		></v-textarea>

			        		<!-- Mensaje de error para el usuario -->
			        		<div class="mt-2 red--text" v-if="errorNota"><h2>{{errorNotaMensaje}}</h2></div>

					        <v-card-actions>
					          <v-spacer></v-spacer>
					          <v-btn
					          	class="text-capitalize"
					          	small
					          	rounded
					            color="error"
					            @click="cancelarWhatsapp()"
					          >
					            Cancelar mensaje
					          </v-btn>
					          <v-btn
					          	class="text-capitalize"
					          	small
					          	rounded
					            color="success"
					            dark
					            v-on:click.prevent="enviarWhatsapp()"
					          >
					            Enviar mensaje
					          	<v-icon right small>mdi-send</v-icon>
					          </v-btn>
					        </v-card-actions>
					      </div>
			        </v-card-text>			
			      </v-window-item>

			    </v-window>

			    <v-divider></v-divider>

			    <v-card-actions>
	          <v-spacer></v-spacer>
	          <!-- botones de enviar y todo eso  -->
	          <v-btn
	          	class="text-capitalize"
	          	small
	            color="green"
	            dark
	            @click="llamada()"
	            v-if="intentos == 1 && contacto == 1"
	          >
	            Enviar motivo
	          </v-btn>

	          <!-- Bloquear el boton de cancelar para que no puedan cancelar hasta no tener un motivo -->
	          <v-btn
	          	v-if="alumno.estatus_llamada == 1 || alumno.estatus_llamada == 3 || alumno.estatus_llamada == 4"
	          	dark
	          	small
	            color="black"
	            class="text-capitalize"
	            @click="dialog=false, nota='',intentos=0,errorNota=false,dialogConfirmaContacto=false,contacto=0,tipoMedioContacto=0,verMotivo =false,dialogLlamada=false"
	          >
	            Cancelar
	          </v-btn>
	        </v-card-actions>
			  </v-card>
	    </v-dialog>
	  </v-row>

	  <v-row justify="center">
	    <v-dialog
	    	persistent
	      v-model="dialogTicket"
	      max-width="600"
	    >
	      <!-- persistent -->
	      <v-card>
          <v-card-title primary-title>
            Agregar Ticket
            <v-spacer></v-spacer>
            <v-btn color="primary" rounded small @click="save()">Agregar</v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="planteles"
                  v-model="plantel"
                  label="Selecciona plantel"
                  return-object
                  item-value="id_plantel"
                  item-text="plantel"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="motivo"
                  label="Motivo de tu ticket"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="show_folio">
                <h3>Tu numero de folio</h3>
                #{{ folio }}
                <h3>Mensaje enviado</h3>
                {{ motivo_aux }}
                <h3>Nos pondremos en contacto contigo muy pronto.</h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="dark" dark @click="limparTicket()" rounded small>Cerrar</v-btn>
          </v-card-actions>
        </v-card>
	    </v-dialog>
	  </v-row>

	</v-container>
</template>

<script>
	var moment = require("moment");
	moment.locale();
	import {mapGetters, mapActions } from 'vuex'

  export default {
    data () {
      return {
      	step: 1,
      	// Datos para agregar un ticket
      	dialogTicket:false,
      	show_folio: false,
    		planteles: [
		      { id_plantel: 1, plantel: "INBI" },
		      { id_plantel: 2, plantel: "FAST ENGLISH" },
		    ],
		    plantel: { id_plantel: 0, plantel: "" },
		    area: { idareas_ticket: 0, area: "" },
		    motivo: "",
		    mensaje: "",
		    estatus: 0,
		    folio: 0,
		    motivo_aux: "",

		    // rechazo de una llamada
      	verMotivo: false,
      	historialAlumno:{},
      	estatusRechazado: false,
      	// Estatus
      	// 0 = FALTA 1 = Sin contacto  2 = contactado  3 = Pte, revision  4 = rechazado 
      	// Llamada = 0, whatsapp = 1
      	tipoMedioContacto: 0,

      	contacto: 0, // saber si hubo o no hubo contacto (0 incio, 1 con contacto, 2 sin contacto)
      	dialogConfirmaContacto: false,

      	errorNotaMensaje:'',
				errorNota:false,
      	intentos:0,
      	dialogLlamada: false,

      	serveraddress:'sip.zadarma.com',
				username:'832513',
				password:'wevg1310',
				destnumber:'',

      	dialogTel: false,
      	errorWhatsapp:false,
      	dialogWha:false,
				mensajeW:'',

      	nota:'',
      	loading:true,
      	search:'',
      	notas:'',
      	dialog:false,
      	alumno:{},
      	asistencias:[],
        headers: [
          {
            text: 'Grupo',
            align: 'start',
            value: 'grupo',
          },
          { text: 'Alumno', value: 'nombre' },
          { text: 'Falta', value: 'fecha_creacion' },
          { text: 'Estatus', value: 'estatus_llamada' },
          { text: 'Detalles', value: 'llamar' },
        ],

        ciclos:[],
		    ciclo:null,
		    idciclo:0,
        
      }
    },

    watch:{
      ciclo () {
      	if(this.ciclo){
	        this.idciclo = this.ciclo.id
	        this.init()
      	}
        // ahora que ya tenemos el ciclo, mandamos a llamar a la funcion de traer los datos del ciclo
      }
    },

    created(){
    	this.getCiclos()
    	var me = this
    	// this.Start()
    // 	webphone_api.parameters['autostart'] = 0;
    // 	webphone_api.onAppStateChange(function (appState) {
	   //    if (appState === 'loaded') {
    //       var serveraddress = webphone_api.getparameter('serveraddress');
    //       if (serveraddress.length < 1) {
    //           serveraddress = webphone_api.getparameter('serveraddress_user');
    //       } // only for demo
    //       var username = webphone_api.getparameter('username');
    //       var password = webphone_api.getparameter('password');
    //       var destination = webphone_api.getparameter('destination');

    //       if (serveraddress.length > 0) {
    //           serveraddress_input.value = serveraddress;
    //       }
    //       if (username.length > 0) {
    //           username_input.value = username;
    //       }
    //       if (password.length > 0) {
    //           password_input.value = password;
    //       }
    //       if (destination.length > 0) {
    //           destination_input.value = destination;
    //       }

    //       // document.getElementById('events').innerHTML = 'EVENT, Loaded';
	   //    } else if (appState === 'started') {
	      	
    //       // document.getElementById('events').innerHTML = 'EVENT, Started';
	   //    } else if (appState === 'stopped') {
	   //    	// me.Stoped()
    //       // document.getElementById('events').innerHTML = 'EVENT, Stopped';
	   //    }
	  	// });

		  // webphone_api.onRegStateChange(function (regState) {
	   //    if (regState === 'registered') {
	   //      // document.getElementById('events').innerHTML = 'EVENT, Registered.';
	   //    } else if (regState === 'unregistered') {
	   //      // document.getElementById('events').innerHTML = 'EVENT, UnRegistered.';
	   //    } else if (regState === 'failed') {
	   //      // document.getElementById('events').innerHTML = 'ERROR, Register failed.';
	   //    }
		  // });

		  // webphone_api.onCallStateChange(function (event, direction, peername, peerdisplayname, line, callid) {
	   //    // document.getElementById('events').innerHTML = 'EVENT, Call ' + event;
	   //    if (event === 'setup') {
	   //      // document.getElementById('btn_hangup').disabled = false;
	   //      if (direction == 1) {
	   //        // means it's outgoing call

	   //      } else if (direction == 2) {
	   //        // means it's icoming call
	   //        // document.getElementById('icoming_call_layout').style.display = 'block';
	   //      }

	   //      // document.getElementById('events').innerHTML = 'EVENT, Call setup';
	   //    }
	   //    //detecting the end of a call, even if it wasn't successfull
	   //    else if (event === 'disconnected') {
	   //    	// me.Stoped()
	   //      // document.getElementById('btn_hangup').disabled = true;
	   //      // document.getElementById('icoming_call_layout').style.display = 'none';
	   //      // document.getElementById('events').innerHTML = 'EVENT, Call disconnected';
	   //    }
		  // });

  		this.init();
  	},

    methods: {
    	init(){
    		this.loading = true
    		this.asistencias = []

    		// Inbi
    		if(this.getusuarioRiesgo.id_plantel > 0 && this.getusuarioRiesgo.id_plantel <= 6 || this.getusuarioRiesgo.id_plantel == 17){
	    		this.$http.get('asistencias.plantel.inbi/'+this.getusuarioRiesgo.id_plantel + '/' + this.idciclo).then(response=>{
	    			console.log(response.body)
	    			// this.asistencias = response.body
	    			for(const i in response.body){
	            response.body[i].fecha_creacion = response.body[i].fecha_creacion.substr(0,10);
	            response.body[i].fecha_creacion = moment(response.body[i].fecha_creacion).format('LL');
	            this.asistencias.push(response.body[i])
	          }
	          this.loading = false
	    		}).catch(error=>{console.log(error);this.loading = false})
	    		// Fast
    		}else{
    			this.$http.get('asistencias.plantel/'+this.getusuarioRiesgo.id_plantel + '/' + this.idciclo).then(response=>{
	    			console.log(response.body)
	    			// this.asistencias = response.body
	    			for(const i in response.body){
	            response.body[i].fecha_creacion = response.body[i].fecha_creacion.substr(0,10);
	            response.body[i].fecha_creacion = moment(response.body[i].fecha_creacion).format('LL');
	            this.asistencias.push(response.body[i])
	          }
	          this.loading = false
	    		}).catch(error=>{console.log(error);this.loading = false})
    		}
    	},

    	abrirInfoAlumno(value){
    		this.nota='',this.intentos=0,this.errorNota=false,this.dialogConfirmaContacto=false,this.contacto=0,this.tipoMedioContacto=0,this.verMotivo =false,
    		this.dialogLlamada = false

    		this.historialAlumno = {}
    		var payload = {
    			id_alumno: value.id_usuario,
    			id_falta:  value.id
    		}

    		// estatus_llamada 
    		// 0 = falta
    		// 1 = Sin contacto
    		// 2 = contactado
    		// 3 = Pendiente 
    		// 4 = Rechazado 
    		if(value.estatus_llamada == 4 || value.estatus_llamada == 3 || value.estatus_llamada == 1){
	    		this.$http.post('asistencias.alumno',payload).then(response=>{
	    			this.historialAlumno = response.body[response.body.length - 1]
	    			// Validamos que no sea rechazado 
	    			if(value.estatus_llamada == 4 || value.estatus_llamada == 3){
	    				this.estatusRechazado = true
	    				this.step = 3
	    			}else{
		    			if(this.historialAlumno.tipo_contacto == 1){
		    				this.step = 4
		    			}else{
		    				this.step = 1
		    			}

	    			}
	    			this.alumno = value
	    			this.dialog = true
	    			console.log(this.estatusRechazado)
	    		}).catch(error=>{
	    			console.log(error)
	    		})
    		}else{
    			this.step = 1
	    		this.alumno = value
	    		this.dialog = true
    		}
    	},

      getColor (estatus) {
        if (estatus > 400) return 'red'
        else if (estatus > 200) return 'orange'
        else return 'green'
      },

	    sinContacto(){
	    	// FAST
	    	// Validamos que nota no vaya vacio
	    	if(this.nota != ''){
		    	if(this.alumno.unidad_negocio == 1){
		    		var updateEstatus = {
			    		estatus: 1,
			    		id: this.alumno.id,
			    	}
			    	this.$http.put('asistencias.update/'+this.alumno.id, updateEstatus).then(response=>{
			    		console.log(response.body)
			    		this.addHistorial(1)
			    		this.init()
			    	}).catch(error=>{console.log(error)})
		    	}else{
		    	  // INBI
		    	  var updateEstatus = {
			    		estatus: 1,
			    		id: this.alumno.id,
			    	}	
			    	this.$http.put('asistencias.update.inbi/'+this.alumno.id, updateEstatus).then(response=>{
			    		console.log(response.body)
			    		this.addHistorial(1)
			    		this.init()
			    	}).catch(error=>{console.log(error)})
		    	}
		    	this.dialog = false
		    	this.notas = false
		    	this.intentos = 0
		    	this.errorNota  = false
				}else{
		    	this.errorNotaMensaje = 'No puedes dejar campos vacios'
	    		this.errorNota = true
		    }
	    },

	    addHistorial(value){
	    	var payload = {
	    		id_alumno: this.alumno.id_usuario,
	    		id_usuario: this.getusuarioRiesgo.id_usuario,
	    		nota: this.nota,
	    		unidad_negocio: this.alumno.unidad_negocio,
	    		id_falta: this.alumno.id,
	    		intento_llamada: value,
	    		fecha_asistencia: this.alumno.fecha_asistencia,
	    		tipo_contacto: this.tipoMedioContacto
	    	}
	    	console.log(this.alumno.fecha_asistencia)
	    	console.log(payload)
	    	this.$http.post('asistencias.historial', payload).then(response=>{
	    		console.log(response.body)
	    		this.nota = ''
	    		this.dialog = false
	    		this.mensajeW = ''
	    		// Reiniciamos el contacto
	    		this.contacto = 0
	    	  
	    	}).catch(error=>{console.log(error)})
	    },

	    llamada(){
	    	if(this.nota != ''){
		    	// FAST
		    	if(this.alumno.unidad_negocio == 1){
						// estatus 3 = Pendiente de revisión
		    		var updateEstatus = {
			    		estatus: 3,
			    		id: this.alumno.id
			    	}
			    	this.$http.put('asistencias.update/'+this.alumno.id, updateEstatus).then(response=>{
			    		console.log(response.body)
			    		this.addHistorial(0)
			    		this.init()
			    	}).catch(error=>{console.log(error)})
		    	}else{
		    	  // INBI
		    	  // estatus 3 = Pendiente de revisión
		    	  var updateEstatus = {
			    		estatus: 3,
			    		id: this.alumno.id
			    	}	
			    	this.$http.put('asistencias.update.inbi/'+this.alumno.id, updateEstatus).then(response=>{
			    		console.log(response.body)
			    		this.errorNota = false
			    		this.addHistorial(0)
			    		this.init()
			    	}).catch(error=>{console.log(error)})
			    }

			    this.intentos = 0
			  }else{
			  	this.errorNotaMensaje = 'No puedes dejar campos vacios'
	    		this.errorNota = true
			  }
		  },

		  mensajeWhatsapp(){
		  	if(this.alumno.celular > 1000000000){
					this.dialogWha = true
				}else{
					this.errorWhatsapp = true
				}
		  },

			cancelarWhatsapp(){
				this.mensajeW = ''
				this.dialogWha = false
			},

			enviarWhatsapp(){
				if(this.mensajeW != ''){
					var url = 'https://api.whatsapp.com/send?phone=+52' + this.alumno.celular + '&text=' + this.mensajeW
					
					this.nota = `Se mando mensaje por whatsapp
` + this.mensajeW
					this.mensajeW = ''
					this.dialogWha = false

					console.log('enviado')

					// Indicar que el medio de contacto es: whatsapp
					this.tipoMedioContacto = 1
					// llamar a la función para indicar que no hubo contacto, que sigue pendiente 
					this.sinContacto()

					window.open(url, '_blank');
				}else{
		    	this.errorNotaMensaje = 'No puedes dejar campos vacios'
	    		this.errorNota = true
		    }

			},

			Start() {
	      var serveraddress = this.getusuarioRiesgo.servidor_sip;
	      var username      = this.getusuarioRiesgo.usuario_sip;
	      var password      = this.getusuarioRiesgo.password_sip;

	      if (typeof (serveraddress) === 'undefined' || serveraddress === null || serveraddress.length < 1) {
	          alert('Set a valid serveaddress.');
	          serveraddress_input.focus();
	          return;
	      }

	      if (typeof (username) === 'undefined' || username === null || username.length < 1) {
	          alert('Set a valid username.');
	          username_input.focus();
	          return;
	      }

	      if (typeof (password) === 'undefined' || password === null || password.length < 1) {
	          alert('Set a valid password.');
	          password_input.focus();
	          return;
	      }

	      if (typeof (serveraddress) !== 'undefined' && serveraddress !== null && serveraddress.length > 0) {
	          webphone_api.setparameter('serveraddress', serveraddress);
	      }

	      webphone_api.setparameter('username', username);
	      webphone_api.setparameter('password', password);

      	webphone_api.start();
  		},

  		Stoped() {
  			this.dialogConfirmaContacto = true
  			this.intentos = 1
  			webphone_api.stop()
  		},

  		Call() {
  			this.dialogLlamada = true
	      var destnr = this.alumno.telefono;
	      var destnr = String(this.getusuarioRiesgo.ext) + String(this.alumno.telefono);
	      if (typeof (destnr) === 'undefined' || destnr === null) {
	          destnr = '';
	      }
	      webphone_api.setparameter('destination', destnr);
	      webphone_api.call(destnr);
	  	},

  		Accept() {
      	webphone_api.accept();
  		},

  		Reject() {
      	webphone_api.reject();
  		},

  		save() {
	      if (this.plantel.id_plantel != 0 && this.motivo != "") {
	        var payload = {
	          id_unidad_negocio: this.plantel.id_plantel,
	          motivo: this.motivo,
	          estatus: 1,
	          id_usuario: this.getusuarioRiesgo.id_usuario,
	          tipousuario: 0

	        };
	        this.$http
	          .post("tickets.add", payload)
	          .then((response) => {
	            this.folio = response.body.id;
	            this.motivo_aux = this.motivo;
	            this.show_folio = true;
	            var historial = {
	              idticket: response.body.id,
	              resupesta: "",
	              motivo: this.motivo,
	              respuestaauxi: "",
	            };

	            this.$http
	              .post("historial_tickets.add", historial)
	              .then((response) => {
	                this.snackbar = true;
	                this.mensaje = "Ticker insertado correctamente";
	                this.motivo = "";
	                this.plantel = { id_plantel: 0, plantel: "" };
	                this.dialogTicket = false
	                this.folio = ''
                  this.motivo_aux = ''
	              })
	              .catch((error) => {
	                console.log(error);
	              });
	          })
	          .catch((error) => {
	            console.log(error);
	          });
	      } else {
	        this.mensaje = "No dejes campos vacios";
	        this.snackbar = true;
	      }
	    },

	    limparTicket(){
	    	this.motivo = "";
        this.plantel = { id_plantel: 0, plantel: "" };
        this.dialogTicket = false
        this.folio = ''
        this.motivo_aux = ''
        this.show_folio = false
	    },

	    volveraLlamar(){
	    	this.dialogLlamada = false
				this.dialogConfirmaContacto = false
				this.contacto = 0
	    	this.dialogTel = true
	    	this.step = 1
	    	this.Call()
	    },

	    getCiclos(){
	    	if(this.getusuarioRiesgo.id_plantel > 0 && this.getusuarioRiesgo.id_plantel <= 6 || this.getusuarioRiesgo.id_plantel == 17){
	        this.$http.get('riesgo.ciclos.inbi').then(response=>{
	          this.ciclos = response.body
	        }).catch(error=>{
	          console.log(error)
	          this.loading = false
	        })
	    	}else{
	    		this.$http.get('riesgo.ciclos.fast').then(response=>{
	          this.ciclos = response.body
	        }).catch(error=>{
	          console.log(error)
	          this.loading = false
	        })
	    	}
      },

    },

    computed:{
    	...mapGetters('login',['getusuarioRiesgo']),
    	currentTitle () {
        switch (this.step) {
          case 1: return 'Sign-up'
          case 2: return 'Create a password'
          default: return 'Account created'
        }
      },
    }
  }
</script>

<style>
	.v-data-table__wrapper{

	}

	.v-data-table__wrapper::-webkit-scrollbar {
		-webkit-appearance: none !important;
	}

	.v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
		width: 10px !important;
	}

	.v-data-table__wrapper::-webkit-scrollbar:vertical{
		width: 10px !important;
	}

	.v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
		display: none;
	}

	.v-data-table__wrapper::-webkit-scrollbar:horizontal{
		height: 10px !important;
	}

	.v-data-table__wrapper::-webkit-scrollbar-thumb{
		background-color: #BABCBF;
		border-radius: 20px;
		border: 1px solid #ededed;
	}


</style>
<template>
	<v-container>
	  <v-row>
	  	<v-col cols="12">
			  <v-data-table
			    :headers="headers"
			    :items="desserts"
			    sort-by="calories"
			    class="elevation-1"
			    :search="search"
			    dense
			    :loading="loading"
			    dense
			  >
			    <template v-slot:top>
			      <v-toolbar
			        flat
			      >
			        <v-toolbar-title>Asistencias</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-text-field
			          label="Buscar"
			          v-model="search"
			          dense
			          hide-details
			          outlined
			        ></v-text-field>
			        <v-spacer></v-spacer>
			        <v-btn 
		            class="mr-2" 
		            small dark color="warning"
		            @click="exportar()">
		            <v-icon>save_alt</v-icon>
		          </v-btn>
			        <v-btn color="primary" small rounded @click="initialize()">Actualizar</v-btn>
			      </v-toolbar>
			    </template>
			  </v-data-table>
  		</v-col>
	  </v-row>
	</v-container>
</template>

<script>
	import XLSX from 'xlsx'
	
	import {mapGetters, mapActions} from 'vuex'

  export default {
    data: () => ({
    	loading:true,
    	search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Alumno',
          align: 'start',
          sortable: false,
          value: 'nombre',
        },
        { text: 'Grupo',          value: 'grupo' },
      ],
      desserts: [],
    }),

    computed: {
    	...mapGetters('login',['getusuarioRiesgo'])
    },


    created () {
      this.initialize()
    },

    methods: {
      initialize () {
      	this.loading = true
      	this.desserts = []
    		this.$http.post('riesgo.alumnos.registro.inbi').then(response=>{
        	this.desserts  = response.body
        	this.loading = false
        }).catch(error=>{
        	console.log(error)
        	this.loading = false
        })
      },


      // EXCEL
      exportar(){
        let xls = {
          data:      this.desserts,
          tHeaders : ['Codigo','Nombre', 'Sal', 'Labortorio'],
          filterVal: ['codigo','nomart', 'sal', 'lab'],
          nomfile :  "Productos"
        }
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
        let data = XLSX.utils.json_to_sheet(this.desserts)
        const workbook = XLSX.utils.book_new()
        const filename = 'Riesgo_Registro_inbi'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        console.log(file)
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*Initialize the form data*/
        this.$http.post('pdfs',formData).then(response=> {
          window.location = this.$http.options.root + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },
    },
  }
</script>

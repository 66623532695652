<template>
	<v-container>
	  <v-row>
	  	<v-col cols="12">
			  <v-data-table
			  	dense
			    :headers="headers"
			    :items="asistencias"
			    class="elevation-1"
			    fixed-header
			    :search="search"
			    :loading="loading"
    			loading-text="Cargando... Espere un momento"
    			item-key="id"
    			sort-by="estatus_llamada"
    			dense
    			height="500"
			  >
			    <!-- :items-per-page="asistencias.length" -->
			    <!-- hide-default-footer -->
			    <template v-slot:item.estatus_llamada="{ item }">
			      <v-chip
			        color="grey lighten-1"
			        dark
			        v-if="item.estatus_llamada==0"
			        small
			      >
			        Pendiente
			      </v-chip>

			      <v-chip
			        color="yellow"
			        v-if="item.estatus_llamada==1"
			        small
			      >
			        Sin Contacto
			      </v-chip>

			      <v-chip
			        color="green"
			        dark
			        v-if="item.estatus_llamada==2"
			        small
			      >
			        Contactado
			      </v-chip>
			    </template>

			    <template v-slot:item.llamar="{ item }">
			    	<v-btn 
			    		color="blue"
			    		dark
			    		small
			    		icon
			    		@click="abrirInfoAlumno(item), dialogWha = false, mensajeW = '', dialogTel = false"
			    	>
			    		<v-icon>info</v-icon>
			    	</v-btn>
			    </template>

			    <template v-slot:top>
			      <v-toolbar
			        flat
			      >
			        <v-toolbar-title>Asistencias</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-text-field
			          v-model="search"
			          label="Buscar"
			          outlined
			          dense
			          hide-details
			        ></v-text-field>
			        <v-spacer></v-spacer>
			        <v-btn color="secondary" small rounded @click="init()">Actualizar</v-btn>
			      </v-toolbar>
			    </template>
			  </v-data-table>
	  	</v-col>
	  </v-row>

	  <v-row justify="center">
	    <v-dialog
	    	persistent
	      v-model="dialog"
	      max-width="600"
	    >
	      <!-- persistent -->
	      <v-card>
	        <v-card-title class="headline">
	         {{ alumno.nombre }}
	        </v-card-title>
	        <v-card-text>
	        	<b>{{alumno.grupo}}</b><br/>
	        	<v-textarea 
	        		v-model="nota" 
	        		outlined 
	        		dense 
	        		hide-details 
	        		label="Notas" 
	        		class="mt-2"
	        		readonly
        		></v-textarea>
	        </v-card-text>
	        <v-card-actions>
	          <v-spacer></v-spacer>
	          <v-btn
	          	dark
	          	small
	          	rounded
	            color="black"
	            @click="dialog=false, nota='',intentos=0,errorNota=false"
	          >
	            Cancelar
	          </v-btn>
	        </v-card-actions>
	      </v-card>
	    </v-dialog>

	  </v-row>
	</v-container>
</template>

<script>
	var moment = require("moment");
	moment.locale("es");
	import {mapGetters, mapActions } from 'vuex'

  export default {
    data () {
      return {
      	errorNotaMensaje:'',
				errorNota:false,
      	intentos:0,
      	dialogLlamada: false,

      	serveraddress:'sip.zadarma.com',
				username:'832513',
				password:'wevg1310',
				destnumber:'',

      	dialogTel: false,
      	errorWhatsapp:false,
      	dialogWha:false,
				mensajeW:'',

      	nota:'',
      	loading:true,
      	search:'',
      	notas:'',
      	dialog:false,
      	alumno:{},
      	asistencias:[],
        headers: [
          {
            text: 'Grupo',
            align: 'start',
            value: 'grupo',
          },
          { text: 'Alumno', value: 'nombre' },
          { text: 'Falta', value: 'fecha_creacion' },
          { text: 'Estatus', value: 'estatus_llamada' },
          { text: 'Detalles', value: 'llamar' },
        ],
        
      }
    },

    created(){
  		this.init();
  	},

    methods: {
    	init(){
    		var alumnos = []
    		var historial = []
    		this.loading = true
    		this.asistencias = []
    		this.$http.get('historial').then(response=>{
        	historial = response.body
	    		this.$http.get('asistencias.fast').then(response=>{
	    			alumnos = response.body
		  			this.$http.get('asistencias.inbi').then(response=>{
		    			for(const i in response.body){
		            alumnos.push(response.body[i])
		          }
		          for(const i in alumnos){
		          	// alumnos[i].fecha_creacion = moment(alumnos[i].fecha_creacion).format('MMMM Do YYYY');
		          	alumnos[i].fecha_creacion = alumnos[i].fecha_creacion.substr(0,10);
	            	alumnos[i].fecha_creacion = moment(alumnos[i].fecha_creacion).format('LL');
		          }
		          this.asistencias = alumnos
		          this.loading = false
		    		}).catch(error=>{console.log(error);this.loading = false})
	    		}).catch(error=>{console.log(error);this.loading = false})
        }).catch(error=>{
        	console.log(error)
        })
    	},


    	abrirInfoAlumno(value){
    		this.alumno = value
    		this.dialog = true
    		this.$http.get('historial').then(response=>{
        	var historial = response.body
        	for(const i in historial){
        		if(this.alumno.id == historial[i].id_falta && this.alumno.unidad_negocio ==  historial[i].unidad_negocio){
        			console.log('historial',historial[i])
        			this.nota = historial[i].nota
        		}
        	}
				}).catch(error=>{
        	console.log(error)
        })

    	},

      getColor (estatus) {
        if (estatus > 400) return 'red'
        else if (estatus > 200) return 'orange'
        else return 'green'
      },


    },

    computed:{
    	...mapGetters('login',['getusuarioRiesgo'])
    }
  }
</script>

<style>
	.v-data-table__wrapper{

	}

	.v-data-table__wrapper::-webkit-scrollbar {
		-webkit-appearance: none !important;
	}

	.v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
		width: 10px !important;
	}

	.v-data-table__wrapper::-webkit-scrollbar:vertical{
		width: 10px !important;
	}

	.v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
		display: none;
	}

	.v-data-table__wrapper::-webkit-scrollbar:horizontal{
		height: 10px !important;
	}

	.v-data-table__wrapper::-webkit-scrollbar-thumb{
		background-color: #BABCBF;
		border-radius: 20px;
		border: 1px solid #ededed;
	}


</style>
<template>
  <v-container >
   
    <v-row justify="space-around">
    
      <v-snackbar v-model="snackbar" :timeout="8000" top color="orange" dark  > {{text}}
        <v-btn color="white" text @click="snackbar = false"> Cerrar </v-btn>
      </v-snackbar>

      <v-col cols="12" v-if="acceso">
        <h2>Bienvenido </br>{{ nombre_completo }}!</h2>
      </v-col>

      <v-col cols="12" v-else>
        <h2>Lo sentimos, {{ nombre_completo }}!</h2>
        <h3>No puedes acceder a este sistema</h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import router from '@/router'
  import {mapActions , mapGetters} from 'vuex'
  export default {
    data(){
      return{
        acceso: false,
        folio_ticket: {},
        mensaje_ticket: "",
        motivo_aux: "",
        nombre_completo: "",
        cargo: "",
        Ticket_generado: false,
        Error: false,
        datos_usuario: {
          id_usuario: 0,
          nombre_completo: "",
          usuario: "",
          password: "",
          id_plantel: "",
          email: "",
          telefono: "",
          id_trabajador: 0,
          idjefe_area:0,
          idauxi_area:0,
          perfil:'',
        },

        password: '',
        email:'',

        snackbar: false,
        y: 'top',
        x: null,
        mode: '',
        timeout: 8000,
        text: '',

        show1: false,
        error: '',


        rules: {
          required: value => !!value || 'Requerido.',
          min: v => v.length >= 8 || 'Minimo 8 caracteres',
          emailMatch: () => ('El correo y/o la contraseña no son correctos')
        },

        NombreAsig: '',
        Nomuser: '',
      }
    },

    computed:{
      ...mapGetters('login',['getusuarioRiesgo']),
    },

    created(){
      this.initialize()
    },

    
    methods:{
      ...mapActions('login',['validarUser','guardarInfo','GetInfoUser','salirLogin']),

      initialize() {
        // Aquí se envían los datos del usuario para saber que perfil tiene
        this.$http.get("tickets.usuarios.getAll/" + this.$route.params.id).then((response) => {
          // Guardamos los datos
          this.datos_usuario = response.body;
          // Mostramos el nombre del usuario
          this.nombre_completo = this.datos_usuario.nombre_completo;

          console.log('datos del usuario',this.datos_usuario)
          // Validamos si es modo administrador
          if(this.datos_usuario.id_perfil == 1 || this.datos_usuario.id_perfil == 24 || this.datos_usuario.id_perfil == 22 || this.datos_usuario.id_perfil == 12 || this.datos_usuario.id_perfil == 3){
            this.acceso = true
            this.datos_usuario.cargo = 'ADMIN'
            this.guardarInfo(this.datos_usuario)
          }else{
            if(this.datos_usuario.id_perfil == 2){
              this.acceso = true
              this.datos_usuario.cargo = 'RECEPCION'
              this.guardarInfo(this.datos_usuario)
            }else{
              if(this.datos_usuario.id_perfil == 9){
                this.acceso = true
                this.datos_usuario.cargo = 'TEACHER'
                this.guardarInfo(this.datos_usuario)
              }else{
                this.acceso = false
                this.salirLogin()
              }

            }
          }

        }).catch((error) => {
          console.log(error);
        });
      },
 
      ingresar (){
        // Traemos la información del usuario
        this.GetInfoUser(usuario).then(response => {
          if(response == false){
            this.text  = "Verifica que tus datos esten correctos"
            this.snackbar = true 
            return
          }else if(response == true){
            this.$router.push({ name: 'listseries' })  
          }else{
            this.text  = response; this.snackbar = true 
          }
        }).catch(error =>{
          console.log(error)
        })
      }
    }
  }
</script>

<style scoped>
  
  .logo{
    align-content: "center";
    text-align: center;

  }
</style>


  
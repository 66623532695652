import Vue from 'vue'
import Vuex from 'vuex'

import router   from '@/router'

import login    from '@/modules/login'
import  createPersistedState  from  'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  mutations: {

  },

  actions: {
  	
  },

  getters:{
		

	},

	modules:{
		login,
	},

  plugins: [createPersistedState()]

})

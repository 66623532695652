<template>
	<v-container>
	  <v-row>
	  	<v-col cols="12">
			  <v-data-table
			  	dense
			    :headers="headers"
			    :items="asistencias"
			    class="elevation-1"
			    fixed-header
			    :search="search"
			    :loading="loading"
    			loading-text="Cargando... Espere un momento"
    			item-key="id"
    			sort-by="estatus_llamada"
    			dense
    			height="500"
			  >
			    <!-- :items-per-page="asistencias.length" -->
			    <!-- hide-default-footer -->
			    <template v-slot:item.estatus_llamada="{ item }">
			      <v-chip
			        color="grey lighten-1"
			        dark
			        v-if="item.estatus_llamada==0"
			        small
			      >
			        Pendiente
			      </v-chip>

			      <v-chip
			        color="yellow"
			        v-if="item.estatus_llamada==1"
			        small
			      >
			        Sin Contacto
			      </v-chip>

			      <v-chip
			        color="green"
			        dark
			        v-if="item.estatus_llamada==2"
			        small
			      >
			        Contactado
			      </v-chip>

			      <v-chip
			        color="yellow"
			        v-if="item.estatus_llamada==3"
			        small
			      >
			        Pendiente
			      </v-chip>
			    </template>

			    <template v-slot:item.llamar="{ item }">
			    	<v-btn 
			    		color="blue"
			    		dark
			    		small
			    		icon
			    		@click="abrirInfoAlumno(item), dialogWha = false, mensajeW = '', dialogTel = false"
			    	>
			    		<v-icon>info</v-icon>
			    	</v-btn>
			    </template>

			    <template v-slot:top>
			      <v-toolbar
			        flat
			      >
			        <v-toolbar-title>Asistencias</v-toolbar-title>
			        <v-divider
			          class="mx-4"
			          inset
			          vertical
			        ></v-divider>
			        <v-spacer></v-spacer>
			        <v-text-field
			          v-model="search"
			          label="Buscar"
			          outlined
			          dense
			          hide-details
			        ></v-text-field>
			        <v-spacer></v-spacer>
			        <v-btn color="secondary" small rounded @click="init()">Actualizar</v-btn>
			      </v-toolbar>
			    </template>
			  </v-data-table>
	  	</v-col>
	  </v-row>

	  <v-row justify="center">
	    <v-dialog
	    	persistent
	      v-model="dialog"
	      max-width="1000"
	    >
	      <!-- persistent -->
	      <v-card>
	        <v-card-title class="headline">
	         {{ alumno.nombre }}
	        </v-card-title>
	        <v-card-text>
	        	<b>{{alumno.grupo}}</b><br/>
	        	<v-row v-for="(historia, i) in historialAlumno" :key="i">
	        		<v-col cols="12" md="6">
			        	<v-textarea
			        		rows="3" 
			        		v-model="historia.nota" 
			        		outlined 
			        		dense 
			        		hide-details 
			        		label="Notas" 
			        		class="mt-2"
			        		readonly
		        		></v-textarea>
	        		</v-col>
	        		<v-col cols="12" md="6" v-if="historia.motivo_rechazo">
	        			<v-textarea
	        				rows="3" 
			        		v-model="historia.motivo_rechazo" 
			        		outlined 
			        		dense 
			        		hide-details 
			        		label="Notas" 
			        		class="mt-2"
		        		></v-textarea>
	        		</v-col>
	        		<v-col cols="12" md="6" v-if=" rechazo == true && i == historialAlumno.length -1">
	        			<v-textarea
	        				rows="3" 
			        		v-model="motivo_rechazo" 
			        		outlined 
			        		dense 
			        		hide-details 
			        		label="Motivo del rechazo" 
			        		class="mt-2"
		        		></v-textarea>
	        		</v-col>
	        	</v-row>
	        	<v-row>
	        		<v-col cols="12" class="pt-0">
	        			<v-btn color="black" dark rounded small @click="rechazo = true" class="mr-2">Rechazar</v-btn> 
	        			<v-btn color="green" dark rounded small @click="aceptarMotivo()" v-if="!rechazo" class="mr-2">Aceptar motivo</v-btn> 
	        			<v-btn color="yellow"     rounded small @click="rechazo = false" v-if="rechazo" class="mr-2">Cancelar</v-btn> 
	        			<v-btn color="green" dark rounded small @click="rechazar()" v-if="rechazo == true && motivo_rechazo != ''">Guardar</v-btn>
	        		</v-col>
	        	</v-row>
		      </v-card-text>
	        <v-card-actions>
	          <v-spacer></v-spacer>
	          <v-btn
	          	dark
	          	small
	          	rounded
	            color="black"
	            @click="dialog=false, nota='',intentos=0,errorNota=false, rechazo=false, motivo_rechazo=''"
	          >
	            Cancelar
	          </v-btn>
	        </v-card-actions>
	      </v-card>
	    </v-dialog>

	  </v-row>
	</v-container>
</template>

<script>
	var moment = require("moment");
	moment.locale("es");
	import {mapGetters, mapActions } from 'vuex'

  export default {
    data () {
      return {
      	rechazo:false,
      	motivo_rechazo:'',

      	historialAlumno:[],

      	errorNotaMensaje:'',
				errorNota:false,
      	intentos:0,
      	dialogLlamada: false,

      	serveraddress:'sip.zadarma.com',
				username:'832513',
				password:'wevg1310',
				destnumber:'',

      	dialogTel: false,
      	errorWhatsapp:false,
      	dialogWha:false,
				mensajeW:'',

      	nota:'',
      	loading:true,
      	search:'',
      	notas:'',
      	dialog:false,
      	alumno:{},
      	asistencias:[],
        headers: [
          {
            text: 'Grupo',
            align: 'start',
            value: 'grupo',
          },
          { text: 'Alumno', value: 'nombre' },
          { text: 'Falta', value: 'fecha_creacion' },
          { text: 'Estatus', value: 'estatus_llamada' },
          { text: 'Detalles', value: 'llamar' },
        ],
        
      }
    },

    created(){
  		this.init();
  	},

    methods: {
    	init(){
    		var alumnos = []
    		var historial = []
    		this.loading = true
    		this.asistencias = []
    		this.$http.get('asistencias.fast.estatus').then(response=>{
    			alumnos = response.body
	  			this.$http.get('asistencias.inbi.estatus').then(response=>{
	    			for(const i in response.body){
	            alumnos.push(response.body[i])
	          }
	          for(const i in alumnos){
	          	alumnos[i].fecha_creacion = alumnos[i].fecha_creacion.substr(0,10);
							alumnos[i].fecha_creacion = moment(alumnos[i].fecha_creacion).format('LL');
	          }
	          this.asistencias = alumnos
	          this.loading = false
	    		}).catch(error=>{console.log(error);this.loading = false})
    		}).catch(error=>{console.log(error);this.loading = false})
    	},


    	abrirInfoAlumno(value){
    		this.alumno = value
    		var payload = {
    			id_alumno: this.alumno.id_usuario,
    			id_falta:  this.alumno.id
    		}

    		this.$http.post('asistencias.alumno',payload).then(response=>{
    			console.log('repsonse',response)
    			this.historialAlumno = response.body
    			for(const i in this.historialAlumno){
    				this.historialAlumno[i].rechazo = false
    			}
    		}).catch(error=>{
    			console.log(error)
    		})

    		this.dialog = true
    // 		this.$http.get('historial').then(response=>{
    //     	var historial = response.body
    //     	for(const i in historial){
    //     		if(this.alumno.id == historial[i].id_falta && this.alumno.unidad_negocio ==  historial[i].unidad_negocio){
    //     			console.log('historial',historial[i])
    //     			this.nota = historial[i].nota
    //     		}
    //     	}
				// }).catch(error=>{
    //     	console.log(error)
    //     })

    	},

      getColor (estatus) {
        if (estatus > 400) return 'red'
        else if (estatus > 200) return 'orange'
        else return 'green'
      },

	    rechazar(){
	    	var historia = this.historialAlumno[this.historialAlumno.length - 1]
	    	this.rechazo = false
	    	historia.motivo_rechazo = this.motivo_rechazo
	    	console.log('rechazar',historia)
	    	this.$http.put('historial.update/'+historia.idhistorial_asistencia,historia).then(response=>{
	    		this.motivo_rechazo = ''
	    		this.rechazo = false
	    		this.dialog = false
	    		this.init()
	    	}).catch(error=>{console.log(error)})
	    },

	    aceptarMotivo(){
	    	var historia = this.historialAlumno[this.historialAlumno.length - 1]
	    	this.rechazo = false
	    	historia.motivo_rechazo = this.motivo_rechazo
	    	this.$http.put('historial.update.aceptar/'+historia.idhistorial_asistencia,historia).then(response=>{
	    		this.motivo_rechazo = ''
	    		this.rechazo = false
	    		this.dialog = false
	    		this.init()
	    	}).catch(error=>{console.log(error)})
	    }

    },

    computed:{
    	...mapGetters('login',['getusuarioRiesgo'])
    }
  }
</script>

<style>
	.v-data-table__wrapper{

	}

	.v-data-table__wrapper::-webkit-scrollbar {
		-webkit-appearance: none !important;
	}

	.v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
		width: 10px !important;
	}

	.v-data-table__wrapper::-webkit-scrollbar:vertical{
		width: 10px !important;
	}

	.v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
		display: none;
	}

	.v-data-table__wrapper::-webkit-scrollbar:horizontal{
		height: 10px !important;
	}

	.v-data-table__wrapper::-webkit-scrollbar-thumb{
		background-color: #BABCBF;
		border-radius: 20px;
		border: 1px solid #ededed;
	}


</style>